export const OPEN_MODAL = 'OPEN_MODAL';

export const openModal = (title, content, actions = []) => ({
  type: OPEN_MODAL,
  title, content, actions,
});

export const CLOSE_MODAL = 'CLOSE_MODAL';

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
