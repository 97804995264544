const styles = theme => ({
  grid: {
    padding: 20,
  },
  swapButton: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    marginTop: -25,
    margin: 'auto',
  },
  buttonParentGrid: {
    textAlign: 'center',
  },
  warningIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
});

export default styles;
