import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './GameEvent.styles';
import TimeTableModal from './TimeTableModal';
import Button from '@material-ui/core/Button';
import Event from './Event';
import ReactGA from 'react-ga';

@withStyles(styles)
class GameEvent extends React.Component {
  constructor(props) {
    super(props);
    this.event = new Event(props.event);
    const eventInfo = this.event.getInfo();

    this.state = {
      currentState: Boolean(eventInfo.currentState),
      countdown: eventInfo.countdown,
      nextState: Boolean(eventInfo.nextState),
      nextEvent: eventInfo.nextEvent,
      modalOpen: false,
    };

    this.interval = setInterval(() => {
      this.setState(() => {
        const eventInfo = this.event.getInfo();
        return {
          currentState: Boolean(eventInfo.currentState),
          countdown: eventInfo.countdown,
          nextState: Boolean(eventInfo.nextState),
          nextEvent: eventInfo.nextEvent,
        };
      });
    }, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  toggleModal = () => {
    const { event } = this.props;
    ReactGA.event({
      category: 'aion.schedule',
      action: 'viewSchedule',
      label: event.name
    });
    this.setState(state => ({
      modalOpen: !state.modalOpen,
    }));
  };

  render() {
    const { event, classes } = this.props;
    const {
      nextEvent,
      currentState,
      countdown,
      modalOpen,
    } = this.state;

    if (nextEvent === undefined || nextEvent === null) return null;

    const inlineStyles = {
      backgroundImage: `url(${event.image})`,
      backgroundSize: 'cover',
    };

    if (event.options.backgroundPosition) {
      inlineStyles.backgroundPosition = event.options.backgroundPosition;
    }

    const statusStyle = {
      backgroundColor: currentState ? '#015a00' : '#650000',
    };

    const openStateVerb = event.options.openStateVerb ? event.options.openStateVerb : 'Opens';
    const closeStateVerb = event.options.closeStateVerb ? event.options.closeStateVerb : 'Closes';

    return (
      <div className={classes.root} onClick={this.toggleModal}>
        <div style={inlineStyles} className={classes.bg}> </div>
        <div className={classes.content}>
          <h2 className={classes.h2}>{event.name}</h2>
          <div className={classes.status}>
            <p style={statusStyle} className={classes.statusText}>{currentState ? event.options.open ? event.options.open : 'OPEN' : event.options.closed ? event.options.closed : 'CLOSED'}</p>
            <p className={classes.countdown}>{countdown}</p>
          </div>
          <p>{currentState ? `${closeStateVerb} ${nextEvent.fromNow()}` : `${openStateVerb} ${nextEvent.fromNow()}`}</p>
          <Button variant="text" className={classes.action} color="secondary">
            View Full Schedule
          </Button>
        </div>
        <TimeTableModal open={modalOpen} title={event.name} event={this.event} />
      </div>
    )
  }
}

export default GameEvent;
