import React from 'react';
import AionTranslator from '../../modules/Aion/Translator/AionTranslator';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import styles from './TranslatorPage.styles';

@withStyles(styles)
class TranslatorPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>Aion Translator - Zephyr Gaming Community</title>
        </Helmet>
        <h2 className={classes.title}>Translator</h2>
        <AionTranslator />
      </div>
    );
  }
}

export default TranslatorPage;
