import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './AccountNavMenu.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { logoutUser } from '../../utils/api.actions';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga'

@withRouter
@withStyles(styles)
@withCookies
@connect(null, { logoutUser })
class AccountNavMenu extends React.Component {
  myAccount = () => {
    this.props.history.push('/profile');
    this.props.toggle();
  };

  logOut = () => {
    ReactGA.event({
      category: 'authentication',
      action: 'logout',
    });
    this.props.cookies.remove('zjwt_token');
    this.props.logoutUser();
    this.props.toggle();
  };

  menuItem = (text, icon, onClick) => {
    const { classes } = this.props;
    return (
      <li onClick={onClick} className={classes.menuItem}>
        <FontAwesomeIcon className={classes.navIcon} icon={icon} />
        <span className={classes.navText}>{text}</span>
      </li>
    );
  };

  divider = () => {
    const { classes } = this.props;
    return (
      <li className={classes.divider}>
        <hr />
      </li>
    );
  };

  render() {
    const { classes, className } = this.props;

    return (
      <div className={classnames(classes.root, className)}>
        <ul>
          {this.menuItem('My Account', ['fa', 'user-circle'], this.myAccount)}
          {this.divider()}
          {this.menuItem('Log Out', ['fa', 'sign-out'], this.logOut)}
        </ul>
      </div>
    );
  }
}

export default AccountNavMenu;
