import React from 'react';

const Disclaimer = (props) => {
  return (
    <div>
      <h1>Disclaimer</h1>
      <p>Last updated: November 20, 2018</p>
      <p>The information contained on https://zephyrgaming.gg website (the "Service") is for general information purposes only.</p>
      <p>Zephyr Legion assumes no responsibility for errors or omissions in the contents on the Service.</p>
      <p>In no event shall Zephyr Gaming be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. Zephyr Legion reserves the right to make additions, deletions, or modification to the contents on the Service at any time without prior notice.</p>
      <p>Zephyr Gaming does not warrant that the website is free of viruses or other harmful components.</p>
      <h2>External links disclaimer</h2>
      <p>https://zephyrgaming.gg website may contain links to external websites that are not provided or maintained by or in any way affiliated with Zephyr Gaming</p>
      <p>Please note that the Zephyr Gaming does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>
    </div>
  );
};

export default Disclaimer;
