import moment from 'moment-timezone';

class Event {
  constructor(event, timezone = 'America/Los_Angeles') {
    this.event = event;
    this.schedule = this.makeSchedule(timezone);
  }

  getSchedule = () => this.schedule;

  getOrderOfDays = () => {
    const now = this.now().startOf('day');
    const order = [];
    for (let i = 0; i < 7; i += 1) {
      order.push(now.clone());
      now.add(24, 'hours');
    }
    return order;
  };

  makeSchedule = (timezone = 'America/Los_Angeles') => {
    const days = this.getOrderOfDays();
    const eventDateTimes = [];
    days.forEach((day) => {
      const events = this.event.events.filter(event => event.day === day.format('dddd').toLowerCase());
      events.forEach((event) => {
        const schedule = {
          open: (event.open === 0 ?
            day.clone().startOf('day') :
            day.clone().hour(event.open).minute(0).second(0).millisecond(0)).tz(timezone),
          close: (event.close === 0 ?
            day.clone().endOf('day').add(1, 'minute') :
            day.clone().hour(event.close).minute(0).second(0).millisecond(0)).tz(timezone),
        };
        eventDateTimes.push(schedule);
      });
    });
    return eventDateTimes;
  };

  now = () => moment().tz('America/Los_Angeles');

  isOpen = () => Boolean(this.getCurrentEvent());

  getCurrentEvent = () => {
    for (let event of this.schedule) {
      if (moment().isBetween(event.open, event.close)) {
        return event;
      }
    }
    return false;
  };

  getNextEvent = () => {
    const events = [];
    this.schedule.forEach((event) => {
      events.push({
        type: 'open',
        date: event.open,
      });
      events.push({
        type: 'close',
        date: event.close,
      });
    });
    events.sort((a, b) => {
      return a.date.toDate() - b.date.toDate();
    });
    for (let event of events) {
      if (this.now().isBefore(event.date)) {
        return event.date;
      }
    }
    return null;
  };

  getCountdown = (nextEvent) => {
    const duration = moment.duration(this.now().diff(nextEvent) - 1000, 'milliseconds');
    const days = duration.days() * -1;
    const hours = duration.hours() * -1;
    const minutes = duration.minutes() * -1;
    const seconds = duration.seconds() * -1;
    let result = '';
    if (days > 0) result = `${result}${days}:`;
    if (days> 0 || hours > 0) result = `${result}${hours}:`;
    if (hours > 0 || minutes > 0) result = `${result}${minutes}:`;
    if (hours > 0 || minutes > 0 || seconds > 0) result = `${result}${(hours > 0 || minutes > 0) && seconds < 10 ? 0 : ''}${seconds}`;
    return result;
  };

  getInfo = () => {
    const isOpen = this.isOpen();
    const nextEvent = this.getNextEvent();
    const countdown = this.getCountdown(nextEvent);
    return {
      nextState: !isOpen,
      countdown,
      currentState: isOpen,
      nextEvent,
    };
  };
}

export default Event;
