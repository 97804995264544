import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import styles from './TranslateConfig.styles';
import { LANG_ASMO, LANG_ELYOS } from '../../../lib/aionTranslator';
import { openModal } from '../../../components/Modal/Modal.actions';
import { actions, races } from './constants';

const COOKIE_NAME = 'aion-translate-lang';

@withStyles(styles)
@connect(null, { openModal })
class TranslateConfig extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    const defaultCookie = {
      race: null,
      otherRace: null,
      action: null,
    };
    let cookie = cookies.get(COOKIE_NAME) || defaultCookie;
    if (typeof cookie !== 'object') {
      cookie = defaultCookie;
    }
    if (Object.values(races).indexOf(cookie.race) === -1) {
      cookie.race = null;
    }
    if (Object.values(races).indexOf(cookie.otherRace) === -1) {
      cookie.otherRace = null;
    }
    if (Object.values(actions).indexOf(cookie.action) === -1) {
      cookie.action = null;
    }
    this.state = {
      race: cookie.race || Object.values(races)[0],
      otherRace: cookie.otherRace || Object.values(races)[1],
      action: cookie.action || Object.values(actions)[0],
    };

    const { race, otherRace, action } = this.state;
    this.chooseLang(race, otherRace, action, false);
  }

  chooseLang = (race, otherRace, action, setCookie = true) => {
    const { cookies, changeLang, openModal } = this.props;
    if (setCookie) {
      cookies.set(COOKIE_NAME, {
        race, otherRace, action,
      }, {path: '/'});
    }

    let lang = null;
    if (race === races.ELYOS && action === actions.SPEAK_TO && otherRace === races.ASMO) {
      lang = LANG_ASMO;
    } else if (race === races.ELYOS && action === actions.UNDERSTAND && otherRace === races.ASMO) {
      lang = LANG_ELYOS;
    } else if (race === races.ASMO && action === actions.SPEAK_TO && otherRace === races.ELYOS) {
      lang = LANG_ELYOS;
    } else if (race === races.ASMO && action === actions.UNDERSTAND && otherRace === races.ELYOS) {
      lang = LANG_ASMO;
    } else {
      openModal('Error', 'Something Went Wrong');
    }

    if (lang !== null) {
      changeLang(lang, action);
    }
  };

  handleChange = (type) => (e) => {
    this.setState(() => {
      const newState = {
        [type]: e.target.value,
      };
      if (type === 'race' || type === 'otherRace') {
        newState[(type === 'race') ? 'otherRace' : 'race'] = (e.target.value === 'Elyos') ? 'Asmodian' : 'Elyos';
      }
      return newState;
    }, () => {
      const { race, otherRace, action } = this.state;
      this.chooseLang(race, otherRace, action);
    });
  };

  createSelector = (type, value, onChange, list) => {
    const { classes } = this.props;
    return (
      <TextField
        id={`select-${type.toLowerCase().replace(' ', '-')}`}
        select
        className={classes.textField}
        value={value}
        InputProps={{
          className: classes.inputField,
        }}
        onChange={onChange}
        SelectProps={{
          classes: {
            select: classes.select,
          },
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
      >
        {list.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  raceSelector = () => {
    return this.createSelector('race', this.state.race, this.handleChange('race'), Object.values(races));
  };

  otherRaceSelector = () => {
    return this.createSelector('other-race', this.state.otherRace, this.handleChange('otherRace'), Object.values(races));
  };

  actionSelector = () => {
    return this.createSelector('Action', this.state.action, this.handleChange('action'), Object.values(actions));
  };

  helpText = () => {
    const { race, action, otherRace } = this.state;

    switch (true) {
      case (race === 'Elyos' && action === 'speak to' && otherRace === 'Asmodian'):
        return 'Use the green box to type what you would like to translate to Asmodian. Press Enter to copy to the clipboard.';
      case (race === 'Elyos' && action === 'understand' && otherRace === 'Asmodian'):
        return 'Type or paste what the Asmodian said in the blue box.';
      case (race === 'Asmodian' && action === 'speak to' && otherRace === 'Elyos'):
        return 'Use the blue box to type what you would like to translate to Elyos. Press Enter to copy to the clipboard.';
      case (race === 'Asmodian' && action === 'understand' && otherRace === 'Elyos'):
        return 'Type or paste what the Elyos said in the green box.';
      default:
        return '';
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.sentence}>
        <Typography component="h3" variant="h4" className={classes.text}>
          I'm an {this.raceSelector()} and I want to {this.actionSelector()} an {this.otherRaceSelector()}.
        </Typography>
        <Typography variant="subtitle1" className={classes.helpText}>
          {this.helpText()}
        </Typography>
      </div>
    );
  };
}

TranslateConfig.propTypes = {
  classes: PropTypes.object,
  changeLang: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

TranslateConfig.defaultProps = {
  classes: {},
  cookies: {},
  openModal: () => {},
};

export default withCookies(TranslateConfig);
