const styles = theme => ({
  root: {
    paddingTop: 40,
    backgroundColor: 'rgba(16,16,16,0.95)',
    composes: [
      'container',
    ],
    color: '#c5c5c5',
  },
});

export default styles;
