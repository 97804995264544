const actions = {
  UNDERSTAND: 'understand',
  SPEAK_TO: 'speak to',
};

const races = {
  ELYOS: 'Elyos',
  ASMO: 'Asmodian',
};

module.exports = {
  actions,
  races,
};
