const styles = theme => ({
  container: {
    padding: 10,
    width: '100%',
    backgroundColor: '#202020',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    marginBottom: 20,
    zIndex: 1050,
    height: 73,
  },
  navbar: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    ...theme.mixins.noSelect(),
    padding: 10,
    paddingTop: 12,
    paddingLeft: 26,
    width: '100%',
    margin: 0,
    listStyleType: 'none',
    '& > li': {
      display: 'inline-block',
      paddingLeft: 10,
      paddingRight: 10,
      '& > a': {
        color: 'rgba(255,255,255,0.8)',
        '&:hover': {
          color: '#fff',
          textDecoration: 'none',
        },
      },
    },
  },
  logo: {
    '& > img': {
      verticalAlign: 'middle',
      maxWidth: 150,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    width: 160,
  },
  mobileSubNav: {
    pointerEvents: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    textAlign: 'center',
    width: '100%',
    background: '#202020',
    position: 'absolute',
    zIndex: 2050,
    top: 50,
    left: 0,
    height: 0,
    padding: 20,
    '& > ul': {
      margin: 0,
      padding: '0 0 10px 0',
      listStyleType: 'none',
      textAlign: 'center',
      '& > li': {
        paddingTop: 10,
        marginTop: 20,
        [theme.breakpoints.down('md')]: {
          borderRadius: 10,
          background: '#474747',
          padding: 10,
        },
        '& > a': {
          verticalAlign: 'middle',
          color: '#fff',
          fontSize: 24,
          '&:hover': {
            color: '#fff',
            textDecoration: 'none',
          },
        },
      },
    }
  },
  transitionFrom: {
    transition: 'all 300ms',
    opacity: 0,
    transform: 'translateY(30px)',
  },
  transitionTo: {
    opacity: 1,
    transform: 'translateY(0px)',
  },
  mobileLogo: {
    marginTop: 20,
    maxWidth: 200,
    margin: 10,
  },
  open: {
    height: 'calc(100vh - 50px)',
    // opacity: 1,
    pointerEvents: 'auto',
  },
  chevronIcon: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    width: '100%',
    textAlign: 'right',
    color: '#fff',
    fontSize: 32,
    height: '100%',
    padding: '0 10px',
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
  mobileOverlayExit: {
    color: '#fff',
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
    fontSize: 30,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 5,
    zIndex: 2000,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default styles;
