import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { closeModal } from './Modal.actions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

@withMobileDialog()
@connect(state => ({
  open: state.modalState.open,
  title: state.modalState.title,
  content: state.modalState.content,
  actions: state.modalState.actions,
}), { closeModal })
class Modal extends React.Component {
  handleClose = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  actions = () => {
    const { actions } = this.props;

    return (
      <DialogActions>
        {actions.map((action, key) => {
          return (
            <Button key={key} onClick={action.onClick || this.handleClose} color={action.color ? action.color : 'primary'}>
              {action.label}
            </Button>
          );
        })}
      </DialogActions>
    )
  };

  render() {
    const { title, content, fullScreen } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title ? title : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        {this.actions()}
      </Dialog>
    );
  }
}

export default Modal;
