import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { withStyles } from '@material-ui/core/styles';
import styles from './TranslationTextArea.styles'

const MAX_CHAR = 195;

@withStyles(styles)
class TranslationTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  isElyos = () => {
    const { race } = this.props;
    let elyos = true;
    if (race !== 'elyos') elyos = false;
    return elyos;
  };

  handleChange = (event) => {
    const { handleChange } = this.props;
    if (event.target.value.length > 195) {
      event.target.value = event.target.value.slice(0, MAX_CHAR);
    }
    this.setState({
      value: event.target.value,
    });
    handleChange(event);
  };

  textCounter = (text, maxLength) => {
    return `${text.length} / ${maxLength}`;
  };

  textCounterColor = () => {
    const { classes } = this.props;
    const { length } = this.state.value;
    const percent90 = Math.floor(MAX_CHAR * .90);
    const percent95 = Math.floor(MAX_CHAR * .95);
    const isWarning = length >= percent90 && length < percent95;
    const isLightDanger = length >= percent95 && length < (Math.floor(MAX_CHAR));
    const isDanger = length === MAX_CHAR;
    return {
      [classes.textCounterWarning]: isWarning,
      [classes.textCounterLightDanger]: isLightDanger,
      [classes.textCounterDanger]: isDanger,
    };
  };

  render() {
    const {
      classes, value, readOnly, handleKeyPress, inputRef, autoFocus
    } = this.props;

    const shouldShrinkLabel = !readOnly || (readOnly && this.state.value.length === 0);

    return (
      <FormControl className={classes.formControl} aria-describedby="name-helper-text">
        <InputLabel
          htmlFor="translate-result"
          shrink={shouldShrinkLabel ? undefined : true}
          classes={{
            root: classNames(classes.labelRoot, {
              [classes.labelAsmo]: !this.isElyos(),
              [classes.labelElyos]: this.isElyos(),
            }),
            formControl: classNames(classes.labelFormControl),
            shrink: classNames(readOnly && classes.labelShrink),
          }}
          FormLabelClasses={{
            focused: !readOnly && classNames({
              [classes.labelAsmoFocused]: !this.isElyos(),
              [classes.labelElyosFocused]: this.isElyos(),
            }),
            filled: classNames(classes.labelFilled),
          }}
        >
          {this.isElyos() ? 'Elyos' : 'Asmodian'}
        </InputLabel>
        <Tooltip
          TransitionComponent={Zoom}
          title={(<span>This area is not editable.</span>)}
          placement="top"
          open={readOnly ? null : false}
          classes={{ tooltip: classes.lightTooltip }}
        >
          <Input
            autoFocus={autoFocus}
            id="translate-result"
            value={value}
            onChange={this.handleChange}
            onKeyDown={handleKeyPress}
            multiline={true}
            rows="10"
            margin="none"
            disableUnderline={true}
            readOnly={readOnly}
            classes={{
              root: classNames(
                classes.bootstrapRoot,
                !readOnly && classes.bootstrapFocus,
              ),
              input: classNames(
                classes.input,
                this.isElyos() && classes.inputElyos,
                !this.isElyos() && classes.inputAsmo,
                readOnly && classes.readOnly,
              ),
            }}
            inputRef={inputRef}
            className={classes.textField}
            inputProps={{
              'data-gramm_editor': 'false', // Disable grammarly
            }}
          />
        </Tooltip>
        <FormHelperText id="name-helper-text" component='div'>
          <Grid container
                spacing={0}
                className={classes.grid}
                direction="row"
                justify="center"
                alignItems="center">
            <Grid item xs={6} className={classes.note}>
              {!readOnly ? <span>Press <b>Enter</b> to copy the translation.</span> : <span>&nbsp;</span>}
            </Grid>
            <Grid item xs={6} className={classNames(
              classes.textCounter,
              this.textCounterColor(),
              readOnly && classes.hide,
            )}>
              {this.textCounter(value, MAX_CHAR)}
            </Grid>
          </Grid>
        </FormHelperText>
      </FormControl>
    );
  }
}

TranslationTextArea.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  handleKeyPress: PropTypes.func,
  inputRef: PropTypes.func,
  autoFocus: PropTypes.bool,
};

TranslationTextArea.defaultProps = {
  classes: {},
  autoFocus: false,
  readOnly: false,
  inputRef: undefined,
  handleKeyPress: () => {},
};

export default TranslationTextArea;
