import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './Schedule.styles';
import Scheduler from '../../components/Scheduler/index';
import gameSchedule from '../../data/gameSchedule';

@withStyles(styles)
class Schedule extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <h2 className={classes.title}>Schedule</h2>
        <Scheduler schedule={gameSchedule} />
      </div>
    );
  }
}

export default Schedule;
