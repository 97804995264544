const styles = theme => ({
  root: {
    composes: [
      'container',
    ],
    marginTop: 20,
  },
  header: {
    color: '#fff',
  },
  title: {
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 300,
    textAlign: 'center',
    color: '#fff',
  },
});

export default styles;