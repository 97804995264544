import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './MobileNavOverlay.styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { logoutUser } from '../../utils/api.actions';
import Logo from '../../images/svg/Logo';
import { withRouter } from 'react-router-dom';

@withRouter
@withStyles(styles)
@connect(state => ({
  username: state.apiState.username,
}), { logoutUser })
class MobileNavOverlay extends React.Component {
  transitionStyle = (key) => ({
    transitionDelay: `${key * 0.05}s`,
  });

  renderAuth = () => {
    const { classes, visible } = this.props;

    const itemClass = classnames(classes.mobileOverlayNavItem, classes.transitionFrom, {
      [classes.transitionTo]: visible,
    });

    return [
      (
        <div className={classnames(itemClass, classes.account)} key={1} style={this.transitionStyle(2)}>
          Hi, Vinlock
        </div>
      ),
      (
        <div className={itemClass} key={2} style={this.transitionStyle(3)}>
          Games
        </div>
      ),
      (
        <div className={itemClass} key={3} style={this.transitionStyle(4)}>
          About
        </div>
      ),
      (
        <div className={classnames(itemClass, classes.account)} key={4} style={this.transitionStyle(5)}>
          Logout
        </div>
      )
    ];
  };

  renderUnAuth = () => {
    const { classes, visible } = this.props;

    const itemClass = classnames(classes.mobileOverlayNavItem, classes.transitionFrom, {
      [classes.transitionTo]: visible,
    });

    return [
      (
        <div className={classnames(itemClass, classes.account)} key={1} style={this.transitionStyle(2)}>
          Login with Discord
        </div>
      ),
      (
        <div className={itemClass} key={2} style={this.transitionStyle(3)}>
          Games
        </div>
      ),
      (
        <div className={itemClass} key={3} style={this.transitionStyle(4)}>
          About
        </div>
      ),
      (
        <div className={itemClass} key={4} style={this.transitionStyle(5)}>
          Test
        </div>
      ),
      (
        <div className={itemClass} key={5} style={this.transitionStyle(6)}>
          Test2
        </div>
      ),
    ];
  };

  render() {
    const { classes, toggle, visible, username } = this.props;

    const rootClasses = classnames(classes.mobileOverlay, {
      [classes.appear]: visible
    });

    return (
      <div className={rootClasses}>
        <div className={classes.mobileOverlayExit} onClick={toggle}>
          <FontAwesomeIcon icon={['fal', 'times']} />
        </div>
        <div className={classnames(classes.mobileOverlayNavLogo, classes.transitionFrom, {
          [classes.transitionTo]: visible,
        })} style={this.transitionStyle(1)}>
          <Logo />
        </div>
        {username ? this.renderAuth() : this.renderUnAuth()}
      </div>
    );
  }
}

MobileNavOverlay.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default MobileNavOverlay;
