let styles = theme => ({
  '@font-face': [
    {
      fontFamily: 'LemonMilk',
      src: 'url("css/fonts/Harabara.otf") format("opentype")',
    },
  ],
  '@global #root': {
    height: '100%',
  },
  '@global body': {
    backgroundColor: '#101010',
    fontFamily: '"Alegreya Sans", sans-serif',
    height: '100%',
    textShadow: '1px 1px 1px rgba(0,0,0,0.004)',
    textRendering: 'optimizeLegibility !important',
    WebkitFontSmoothing: 'antialiased !important',
  },
  '@global html': {
    height: '100%',
  },
  '@global a.active': {
    color: '#fff !important',
  },
  body: {
    height: '100%',
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    zIndex: 2,
    padding: '0 !important',
    composes: [
      'container-fluid',
    ],
    color: '#000',
    fontFamily: '"Alegreya Sans", sans-serif',
    fontSize: 20,
    fontWeight: 400,
    '& h1': {
      fontFamily: '"Alegreya Sans", sans-serif',
      fontWeight: 700,
    },
  },
  contentBody: {
    [theme.breakpoints.between('xs', 'md')]: {
      '&::before': {
        willChange: 'transform',
      },
      WebkitOverflowScrolling: 'touch',
    },
    width: '100%',
    boxSizing: 'border-box',
    height: '100%',
    flex: 1,
    backgroundColor: '#101010',
    flexGrow: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: '#1589b5',
    },
  },
  title: {
    composes: [ 'display-3' ],
  },
  container: {
    minHeight: '100%',
    flex: '1 1 auto',
    position: 'relative',
    display: 'flex',
  },
  bg: {
    opacity: .2,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    position: 'absolute',
    display: 'block',
    '-ms-background-size': 'cover',
    '-o-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-webkit-background-size': 'cover',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  },
  navBar: {},
  footer: {},
  routerRoot: {
    height: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
});

export default (theme) => {
  return styles(theme);
};

// export default styles;
