import esportsBg from '../../images/backgrounds/esports_bg.jpg';
import esportsBgMobile from '../../images/backgrounds/esports_bg_mobile.jpg';

const styles = theme => ({
  root: {
    composes: [
      'container-fluid',
    ],
  },
  // panel: {
  //   [theme.breakpoints.between('xs', 'md')]: {
  //     marginBottom: 40,
  //   },
  //   [theme.breakpoints.between('md', 'xl')]: {
  //     marginBottom: 20,
  //   },
  // },
  panelLeft: {
    paddingRight: 20,
    [theme.breakpoints.between('xs', 'md')]: {
      paddingRight: 0,
      marginBottom: 40,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      paddingRight: 20,
    },
  },
  hiddenPanel: {
    display: 'none',
  },
  comingsoon: {
    color: '#fff',
    height: '80vh',
    width: '100%',
    '& > h2': {
      width: '100%',
      textAlign: 'center',
      ...theme.mixins.verticalAlign(),
    }
  },
  panel: {
    color: '#fff',
    [theme.breakpoints.between('xs', 'md')]: {
      minHeight: 0,
    },
    minHeight: 'calc(100vh - 50px)',
    backgroundPosition: 'center center',
    textAlign: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.between('xs', 'md')]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 50,
    },
    '& > h2': {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 300,
      maxWidth: 800,
      margin: '0 auto',
      marginTop: 80,
      filter: 'drop-shadow( -5px -5px 5px rgba(10,10,10,1) ) drop-shadow( 5px 5px 5px rgba(10,10,10,1) )',
      WebkitFilter: 'drop-shadow( -5px -5px 5px rgba(10,10,10,1) ) drop-shadow( 5px 5px 5px rgba(10,10,10,1) )',
      lineHeight: 1.5,
      [theme.breakpoints.between('xs', 'md')]: {
        fontSize: 24,
        marginTop: 40,
      },
    },
    '& > h4': {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      maxWidth: 700,
      margin: '0 auto',
      marginTop: 20,
      filter: 'drop-shadow( -5px -5px 5px rgba(10,10,10,1) ) drop-shadow( 5px 5px 5px rgba(10,10,10,1) )',
      WebkitFilter: 'drop-shadow( -5px -5px 5px rgba(10,10,10,1) ) drop-shadow( 5px 5px 5px rgba(10,10,10,1) )',
      lineHeight: 1.5,
      [theme.breakpoints.between('xs', 'md')]: {
        fontSize: 18,
        marginTop: -20,
      },
    },
    '> & hr': {
      color: '#fff',
    },
  },
  logoDiv: {
    textAlign: 'center',
  },
  logo: {
    [theme.breakpoints.between('xs', 'md')]: {
      width: 80,
    },
    width: 200,
  },
  mission: {
    backgroundImage: `url(${esportsBg})`,
    [theme.breakpoints.between('xs', 'md')]: {
      backgroundImage: `url(${esportsBgMobile})`,
    },
  },
});

export default styles;
