import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { deAuth, getUser, logoutUser } from './api.actions';
import { closeModal, openModal } from '../components/Modal/Modal.actions';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

@withCookies
@connect(state => ({
  admin: state.apiState.admin,
  authenticated: state.apiState.authenticated,
}), { logoutUser, getUser, openModal, closeModal })
class OnPageChange extends React.Component {
  componentDidUpdate(prevProps) {
    const { location, cookies, getUser, authenticated } = this.props;
    // If location changed
    if (location.pathname !== prevProps.location.pathname) {
      // Scroll to top.
      window.scrollTo(0, 0);
      document.getElementById('scrollableDiv').scrollTop = 0;
      // Get User
      if (authenticated) {
        getUser();
      }
    }

    if (!cookies.get('zjwt_token')) {
      deAuth();
    }

    const parsed = queryString.parse(this.props.location.search);
    if (parsed.error === 'UNVERIFIED') {
      this.openModal('UNVERIFIED');
    } else if (parsed.success === 'JOINED') {
      this.openModal('JOINED');
    } else if (parsed.error === 'NOT_LEGION') {
      this.openModal('NOT_LEGION');
    } else if (parsed.error) {
      this.openModal('INTERNAL_ERROR');
    }
  }

  openModal = (type) => {
    let title = null;
    let content = null;
    let actions = [];
    this.props.history.push({
      search: ''
    });
    switch(type) {
      case 'NOT_LEGION':
        title = 'Must be a Member of the Legion';
        content = 'If you are a member of the Legion already, you must be given the correct role in discord. Please contact Vinlock.'
        actions = [
          {
            label: 'Close',
            onClick: () => this.props.closeModal(),
          },
        ];
        break;
      case 'UNVERIFIED':
        title = 'Unverified E-Mail';
        content = 'You must have a verified Discord account to log in.';
        actions = [
          {
            label: 'Close',
            onClick: () => this.props.closeModal(),
          },
        ];
        break;
      case 'JOINED':
        title = 'Authentication Successful';
        content = 'Thank you for joining Zephyr Discord.';
        actions = [
          {
            label: 'Open Discord',
            onClick: () => {
              window.location = 'discord://discordapp.com/channels/326507689356165120/326507689356165120';
            },
          },
          {
            label: 'Close',
            onClick: () => this.props.closeModal(),
          },
        ];
        break;
      case 'INTERNAL_ERROR':
        title = 'Something went wrong!';
        content = 'An error has occurred, please try again later.';
        actions = [
          {
            label: 'Close',
            onClick: () => this.props.closeModal(),
          },
        ];
        break;
      default:
        throw new Error('Invalid Modal');
    }
    this.props.openModal(title, content, actions);
  };

  render() {
    return this.props.children;
  }
}

const OnPageChangeContainer = withRouter(OnPageChange);

export default OnPageChangeContainer;
