import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './GameSelectionPanel.styles';
import Checkbox from '@material-ui/core/Checkbox';
import client from '../../../utils/api';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

const games = {
  aion: 'Aion Online',
  overwatch: 'Overwatch',
  wow: 'World of Warcraft',
  cod: 'Call of Duty: Blackout',
  fortnite: 'Fortnite',
  apex: 'Apex Legends',
};

@withStyles(styles)
@connect(state => ({
  roles: state.apiState.roles,
  loading: state.apiState.loading,
}))
class GameSelectionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      disabled: {},
    };
  }

  componentDidMount() {
    const { games } = this.props.roles;
    this.setState({
      options: games,
    });
  }

  handleChange = (option) => (event) => {
    const { checked } = event.target;
    this.setState((state) => {
      return {
        options: Object.assign(state.options, {
          [option]: checked,
        }),
        disabled: Object.assign(state.disabled, {
          [option]: true,
        }),
      };
    });
    client(true).post(`/account/profile/games/${option}`, {
      value: event.target.checked,
    }).then(response => response.data)
      .then((data) => {
        this.setState((state) => {
          return {
            options: data,
            disabled: Object.assign(state.disabled, {
              [option]: undefined,
            }),
          };
        });
      });
  };

  render() {
    const { classes, loading } = this.props;
    const { options, disabled } = this.state;
    if (loading || options === {}) {
      return (
        <div>
          <CircularProgress className={classes.progress} />
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <FormGroup row>
          {Object.keys(options).map((option, key) => {
            return (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={this.state.options[option]}
                    onChange={this.handleChange(option)}
                    value={option}
                    color="primary"
                    disabled={disabled[option] === true}
                  />
                }
                label={games[option]}
              />
            );
          })}
        </FormGroup>
      </div>
    )
  }
}

export default GameSelectionPanel;
