const styles = theme => ({
  root: {
    borderRadius: 12,
    height: 200,
    margin: 20,
    position: 'relative',
    boxShadow: 'inset 0.5px 0.5px 0.5px #9b9b9b, 0px 0px 10px rgba(16,16,16,0.5)',
    cursor: 'pointer',
    color: '#F0F0F0 !important',
  },
  bg: {
    borderRadius: 12,
    zIndex: 1,
    opacity: .6,
    filter: 'blur(1px)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'block',
    '-ms-background-size': 'cover',
    '-o-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-webkit-background-size': 'cover',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  h2: {
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: '1.5rem',
      overflowwrap: 'break-word',
      maxWidth: 170,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '2rem',
    },
  },
  content: {
    zIndex: 2,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 20,
  },
  status: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontWeight: 700,
    textAlign: 'right',
    // marginRight: -20,
    marginTop: 20,
  },
  statusText: {
    margin: 0,
    paddingRight: 20,
    paddingLeft: 20,
    color: '#fff',
    display: 'inline-block',
    padding: 2,
  },
  countdown: {
    margin: 0,
    marginRight: 20,
    fontSize: 30,
  },
  action: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: 20,
    zIndex: 1100,
    backgroundColor: 'rgba(0,0,0,0)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
      color: '#3185c2',
    },
  },
});

export default styles;
