const styles = theme => ({
  paperRoot: {
    backgroundColor: '#101010',
    '& h6': {
      color: '#fff',
    },
  },
  paper: {
    backgroundColor: '#101010',
    color: '#fff',
  },
  cell: {
    color: '#fff',
  },
});

export default styles;
