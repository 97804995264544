import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faFacebook, faTwitch, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faSignOut, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faTimes, faChevronDown } from '@fortawesome/pro-light-svg-icons';

library.add(
  faTwitter,
  faFacebook,
  faTwitch,
  faDiscord,
  faCaretDown,
  faSignOut,
  faUserCircle,
  faEllipsisH,
  faBars,
  faTimes,
  faChevronDown,
);
