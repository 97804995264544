import { OPEN_NOTIFICATION, CLOSE_NOTIFICATION } from './Notification.actions';

const INITIAL_STATE = {
  open: false,
  message: null,
  vertical: 'bottom',
  horizontal: 'center',
  icon: null,
  variant: null,
  action: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_NOTIFICATION:
      return {
        ...state,
        open: true,
        message: action.message,
        vertical: action.vertical,
        horizontal: action.horizontal,
        icon: action.icon,
        variant: action.variant,
        action: action.action,
      };
    case CLOSE_NOTIFICATION:
      return {
        ...INITIAL_STATE,
        variant: state.variant,
        vertical: state.vertical,
        horizontal: state.horizontal,
        action: state.action,
      };
    default:
      return state;
  }
};

export default reducer;
