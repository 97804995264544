import axios from 'axios';
// import { store } from '../AppProvider';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const getCookie = function (name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
  return null;
};

const client = () => {
  const zjwt = getCookie('zjwt_token');

  const config = {
    baseURL: apiEndpoint,
  };

  if (zjwt) {
    config.headers = {
      'Authorization': `ZEP ${zjwt}`,
    };
  }

  return axios.create(config);
};

export default client;
