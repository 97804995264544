import { OPEN_MODAL, CLOSE_MODAL } from './Modal.actions';

const INITIAL_STATE = {
  open: false,
  title: '',
  content: null,
  actions: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        open: true,
        title: action.title,
        content: action.content,
        actions: action.actions,
      };
    case CLOSE_MODAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
