import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './DisclaimerPage.styles';
import Disclaimer from '../../legal/Disclaimer';

@withStyles(styles)
class DisclaimerPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Disclaimer />
      </div>
    );
  }
}

export default DisclaimerPage;
