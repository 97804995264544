import React from 'react';
import { Route, NavLink } from 'react-router-dom';
import TranslatorPage from './TranslatorPage';
import { SubNav } from '../../components/NavBar';
import aion from '../../images/logos/aion_home.png';
import Schedule from './Schedule';
import TranslateIcon from '@material-ui/icons/Translate';
import ScheduleIcon from '@material-ui/icons/Schedule';
import styles from './AionRoot.styles';
import { withStyles } from '@material-ui/core/styles';

@withStyles(styles)
class AionRoot extends React.Component {
  navList = () => {
    const { classes } = this.props;
    return [
      <li key={0}>
        <NavLink to="/aion/tools/translator" activeClassName="active">
          <TranslateIcon className={classes.icon} />Translator
        </NavLink>
      </li>,
      <li key={1}>
        <NavLink to="/aion/tools/schedule" activeClassName="active">
          <ScheduleIcon className={classes.icon} />Schedule
        </NavLink>
      </li>,
    ];
  };

  render() {
    return (
      <div>
        <SubNav
          title="Aion Online"
          logo={aion}
          homePath="/aion"
          navList={this.navList()}
        />
        <Route path="/aion/tools/translator" component={TranslatorPage} />
        <Route path="/aion/tools/schedule" component={Schedule} />
      </div>
    );
  }
}

export default AionRoot;
