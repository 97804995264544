import _ from 'lodash';

export const SUMMON_SNACKBAR = 'SUMMON_SNACKBAR';

export const summonSnackbar = (message, options) => {
  options = _.defaults(options, {
    vertical: 'bottom',
    horizontal: 'center',
    variant: null,
    icon: null,
  });
  return (dispatch, getState) => {
    const summon = () => dispatch({
      type: SUMMON_SNACKBAR,
      message,
      ...options,
    });
    const { open } = getState().snackbarState;
    if (open) {
      dispatch(dismissSnackbar());
      setTimeout(summon, 250);
    } else {
      summon();
    }
  };
};

export const DISMISS_SNACKBAR = 'DISMISS_SNACKBAR';

export const dismissSnackbar = () => dispatch => dispatch({ type: DISMISS_SNACKBAR });
