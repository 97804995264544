import client from './api';
import ReactGA from 'react-ga';
import { expireCookie } from 'redux-cookie';

export const GET_USER = 'GET_USER';
export const LOADING_USER = 'LOADING_USER';

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_USER });
    client(true).get('/account/profile')
      .then(response => response.data)
      .then((data) => {
        ReactGA.set({
          userId: data.userId,
          discordId: data.discordId,
        });
        dispatch({
          type: GET_USER,
          data: {
            username: data.username,
            admin: Boolean(data.admin),
            roles: data.roles,
            legionMember: data.legionMember,
            retrieved: true,
            authenticated: true,
            profile: data.profile,
            avatar: data.avatar,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER,
          data: {
            username: null,
            admin: false,
            roles: {},
            legionMember: false,
            retrieved: true,
            authenticated: false,
            profile: {},
            avatar: null,
          },
        });
      });
  };
};

export const LOGOUT_USER = 'LOGOUT_USER';

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(expireCookie('zjwt_token'));
    client().post('/auth/logout', {})
      .then(response => response.status)
      .then((status) => {
        if (status === 204) {
          dispatch({ type: LOGOUT_USER });
        }
      });
  };
};

export const DE_AUTH = 'DE_AUTH';

export const deAuth = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT_USER });
  };
};