import React from 'react';
import { default as MaterialSnackbar } from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import styles from './Snackbar.styles';
import { dismissSnackbar} from './Snackbar.actions';

@withStyles(styles)
@connect(state => ({
  open: state.snackbarState.open,
  message: state.snackbarState.message,
  vertical: state.snackbarState.vertical,
  horizontal: state.snackbarState.horizontal,
  variant: state.snackbarState.variant,
  icon: state.snackbarState.icon,
}), { dismissSnackbar })
class Snackbar extends React.Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.dismissSnackbar();
  };

  render() {
    const { className, classes, message, open, vertical, horizontal, variant, icon } = this.props;
    return (
      <div>
        <MaterialSnackbar
          open={open}
          anchorOrigin={{ vertical, horizontal }}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
              root: classnames(classes.contentRoot, {
                [classes.warning]: variant === 'warning',
                [classes.success]: variant === 'success',
                [classes.error]: variant === 'error',
                [classes.info]: variant === 'info',
              }),
            },
          }}
          message={(
            <span id="message-id" className={classes.message}>
              {icon ? <icon className={classes.icon} /> : null}
              {message}
            </span>
          )}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          className={classnames(classes.root, className)}
        />
      </div>
    );
  }
}

export default Snackbar;
