import idgeldome from '../images/screenshots/idgeldome.jpg';
import dredgion from '../images/screenshots/dredgion.png';
import discipline from '../images/screenshots/discipline.jpg';
import evergale from '../images/screenshots/evergale.png';
import harmony from '../images/screenshots/harmony.jpg';
import lakrumFort from '../images/screenshots/lakrumfort.jpg';
import divineFort from '../images/screenshots/divinefort.jpg';

const gameSchedule = [
  {
    name: 'Idgel Dome',
    events: [
      { day: 'tuesday', open: 10, close: 12 },
      { day: 'tuesday', open: 18, close: 24 },
      { day: 'friday', open: 10, close: 12 },
      { day: 'friday', open: 18, close: 24 },
      { day: 'sunday', open: 10, close: 12 },
      { day: 'sunday', open: 18, close: 24 },
    ],
    image: idgeldome,
    options: {},
  },
  {
    name: 'Ashunatal Dredgion',
    events: [
      { day: 'monday', open: 9, close: 12 },
      { day: 'monday', open: 18, close: 24 },
      { day: 'thursday', open: 9, close: 12 },
      { day: 'thursday', open: 18, close: 24 },
      { day: 'sunday', open: 9, close: 12 },
      { day: 'sunday', open: 18, close: 24 },
    ],
    image: dredgion,
    options: {},
  },
  {
    name: 'Arena of Discipline',
    events: [
      { day: 'sunday', open: 18, close: 24 },
      { day: 'monday', open: 18, close: 24 },
      { day: 'tuesday', open: 18, close: 24 },
      { day: 'wednesday', open: 18, close: 24 },
      { day: 'thursday', open: 18, close: 24 },
      { day: 'friday', open: 18, close: 24 },
      { day: 'saturday', open: 18, close: 24 },
    ],
    image: discipline,
    options: {},
  },
  {
    name: 'Arena of Harmony',
    events: [
      { day: 'sunday', open: 18, close: 0 },
      { day: 'monday', open: 18, close: 0 },
      { day: 'tuesday', open: 18, close: 0 },
      { day: 'wednesday', open: 18, close: 0 },
      { day: 'thursday', open: 18, close: 0 },
      { day: 'friday', open: 18, close: 0 },
      { day: 'saturday', open: 18, close: 0 },
    ],
    image: harmony,
    options: {},
  },
  {
    name: 'Evergale Canyon',
    events: [
      { day: 'wednesday', open: 10, close: 12 },
      { day: 'wednesday', open: 18, close: 0 },
      { day: 'saturday', open: 10, close: 12 },
      { day: 'saturday', open: 18, close: 0 },
      { day: 'sunday', open: 10, close: 12 },
      { day: 'sunday', open: 18, close: 0 },
    ],
    image: evergale,
    options: {},
  },
  {
    name: 'Lakrum Fortress Siege',
    events: [
      { day: 'tuesday', open: 19, close: 20 },
      { day: 'thursday', open: 19, close: 20 },
      { day: 'saturday', open: 16, close: 17 },
    ],
    image: lakrumFort,
    options: {
      openStateVerb: 'Vulnerable',
      closeStateVerb: 'Invulnerable',
    },
  },
  {
    name: 'Divine Fortress Siege',
    events: [
      { day: 'sunday', open: 16, close: 17 },
    ],
    image: divineFort,
    options: {
      openStateVerb: 'Vulnerable',
      closeStateVerb: 'Invulnerable',
    },
  },
];

export default gameSchedule;
