import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './SubNav.styles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

@withRouter
@withStyles(styles)
class SubNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  goHome = () => {
    const { history, homePath } = this.props;
    history.push(homePath);
  };

  toggleMobileNavMenu = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  render() {
    const { logo, classes, navList, title } = this.props;
    const { open } = this.state;

    const itemClass = classnames(classes.mobileOverlayNavItem, classes.transitionFrom, {
      [classes.transitionTo]: open,
    });

    return (
      <div className={classes.container}>
        <span className={classes.logo} onClick={this.goHome}>
          <img src={logo} alt={title} />
        </span>
        <ul className={classes.navbar}>
          {navList}
        </ul>
        <div className={classes.chevronIcon} onClick={this.toggleMobileNavMenu}>
          <FontAwesomeIcon icon={['fal', 'chevron-down']} />
        </div>
        {/* MOBILE SUB NAV */}
        <div className={classnames(itemClass, classes.mobileSubNav, {
          [classes.open]: open,
        })}>
          <div className={classes.mobileOverlayExit} onClick={this.toggleMobileNavMenu}>
            <FontAwesomeIcon icon={['fal', 'times']} />
          </div>
          <img src={logo} alt={title} className={classes.mobileLogo} />
          <ul>
            {navList}
          </ul>
        </div>
      </div>
    );
  }
}

SubNav.propTypes = {
  homePath: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SubNav;
