const styles = theme => ({
  root: {
    backgroundColor: '#1d1d1d',
    minWidth: 280,
    fontSize: 16,
    '& ul': {
      listStyleType: 'none',
      padding: '10px 0 10px 0',
      margin: 0,
      borderBottomLeftRadius: 6,
    },
  },
  menuItem: {
    display: 'inline-block',
    width: '100%',
    padding: '10px 20px 10px 20px',
    '&:hover': {
      backgroundColor: '#1589b5',
      cursor: 'pointer',
    },
  },
  navLink: {
    color: '#fff',
    '&:hover': {
      color: '#fff',
    },
  },
  navIcon: {
    verticalAlign: 'middle',
    marginRight: 10,
  },
  navText: {
    verticalAlign: 'middle',
  },
  divider: {
    padding: '0px 20px 0px 20px',
    lineHeight: 0,
    '& hr': {
      display: 'block',
      height: 1,
      border: 0,
      borderTop: '1px solid #787878',
      margin: '1em 0',
      padding: 0,
    },
  },
});

export default styles;