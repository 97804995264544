const asmoCharTable = [
  {
    char: [
      '','j','k','h','i','n','o','l','m','r','s','p','q','v','w','t','u','z','G','b','c','J','a','f','g','d','e',
    ],
    next: [
      0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,3,2,2,3,2,2,2,2,2,
    ],
  },
  {
    char: [
      '','e','f','c','d','i','j','g','h','m','n','k','l','q','r','o','p','u','v','s','t','y','z','a','b','I','J',
    ],
    next: [
      0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,2,2,3,3,
    ],
  },
  {
    char: [
      '','f','g','d','e','j','k','h','i','n','o','l','m','r','s','p','q','v','w','t','u','z','G','b','c','J','a',
    ],
    next: [
      0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,3,2,2,3,2,
    ],
  },
  {
    char: [
      '','g','h','e','f','k','l','i','j','o','p','m','n','s','t','q','r','w','x','u','v','G','H','c','d','a','b'
    ],
    next: [
      0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,3,3,2,2,2,2,
    ],
  },
];

const elyosCharTable = [
  {
    char: [
      '','i','h','k','j','m','l','o','n','q','p','s','r','u','t','w','v','y','x','a','z','c','b','e','d','g','f',
    ],
    next: [
      0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,2,1,2,2,2,2,2,2,
    ],
  },
  {
    char: [
      '','d','c','f','e','h','g','j','i','l','k','n','m','p','o','r','q','t','s','v','u','x','w','z','y','b','a',
    ],
    next: [
      0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,2,2,
    ],
  },
  {
    char: [
      '','e','d','g','f','i','h','k','j','m','l','o','n','q','p','s','r','u','t','w','v','y','x','a','z','c','b',
    ],
    next: [
      0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,2,1,2,2,
    ],
  }
];

const letterIndex = (letter) => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  return alphabet.indexOf(letter) + 1;
};

export const LANG_ASMO = 0;
export const LANG_ELYOS = 1;

const aionTranslate = (input, language) => {
  let tableIndex = 0;
  let output = '';

  if (input.length > 195) {
    input = input.slice(0, 195);
  }

  input = input.toLowerCase();

  let table = asmoCharTable;
  if (language === LANG_ELYOS) {
    table = elyosCharTable;
  }

  for (let i = 0; i < input.length; i += 1) {
    const index = letterIndex(input.charAt(i));
    if (index !== 0) {
      output = output.concat(table[tableIndex].char[index]);
      tableIndex =  table[tableIndex].next[index];
    } else {
      output = output.concat(input.charAt(i));
      tableIndex = 0;
    }
  }

  return output;
};

export default aionTranslate;
