import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const mixins = {
  noSelect: () => ({
    webkitTouchCallout: 'none',
    webkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    '-ms-user-select': 'none',
    userSelect: 'none',
    '-webkit-tap-highlight-color': 'transparent',
    '&:focus': {
      outline: 'none !important',
    },
  }),
  bodyPadding: () => ({
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 120,
  }),
  verticalAlign: () => ({
    position: 'relative',
    top: '50%',
    webkitTransform: 'translateY(-50%)',
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)',
  }),
  unselectable: () => ({
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  }),
  embedResponsive: () => ({
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: 0,
    overflow: 'hidden',
    '&::before': {
      display: 'block',
      content: '""',
    },
    '& > *': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      minHeight: '100%',
      border: 0,
    },
  }),
  embedResponsive16x9: () => ({
    '&::before': {
      paddingTop: '56.25%',
    },
  }),
};

const theme = {
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#496a8e',
        '&:hover': {
          backgroundColor: '#23374A',
        },
      },
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#101010',
      },
    },
  },
  palette: {
    twitch: {
      light: '#b9a3e3',
      dark: '#6441a5',
    },
    twitter: {
      dark: '#1da1f2',
    },
    facebook: {
      dark: '#3b5998',
    },
    link: {
      hover: '#007bff',
    },
    type: 'dark',
  },
  mixins,
};

const AppTheme = (props) => (
  <MuiThemeProvider theme={createMuiTheme(theme)}>
    {props.children}
  </MuiThemeProvider>
);

export default AppTheme;
