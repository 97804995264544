import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './NavBar.styles';
import Logo from '../../images/svg/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AccountNavMenu from '../../components/AccountNavMenu';
import classnames from 'classnames';
import { logoutUser } from '../../utils/api.actions';
import Dropdown from './Dropdown';
import ExtraDropdown from './ExtraDropdown';
import GamesDropdown from './GamesDropdown';
import MobileNavOverlay from '../MobileNavOverlay';
import ReactGA from 'react-ga';

const OAUTH_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/discord/oauth`;

@withRouter
@withStyles(styles)
@connect(state => ({
  username: state.apiState.username,
  avatar: state.apiState.avatar,
}), { logoutUser })
class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountNavState: false,
      mobileNavState: false,
    };
  }

  runClickFunction = (event) => {
    const { accountNavState } = this.state;
    if (accountNavState && event.target.closest('.accountNavProtectClick') === null) {
      this.toggleAccountNav();
    }
    document.removeEventListener('click', this.runClickFunction);
  };

  toggleAccountNav = () => {
    const { username } = this.props;
    if (username) {
      this.setState((state) => {
        if (!state.accountNavState) {
          document.addEventListener('click', this.runClickFunction);
        }
        return {
          accountNavState: !state.accountNavState,
        };
      });
    }
  };

  loginWithDiscord = () => {
    ReactGA.event({
      category: 'homepage.discord',
      action: 'login',
    });
  };

  authNav = () => {
    const { username, classes, avatar } = this.props;
    const { accountNavState } = this.state;
    let accNav = (
      <li className={classes.loggedOutNav}>
        <a href={`${OAUTH_ENDPOINT}?redirect_url=${window.location.href}`} onClick={this.loginWithDiscord} className={classes.faButton}>
          <FontAwesomeIcon icon={['fab', 'discord']} />
          <span className={classes.faLinkText}>Login via Discord</span>
        </a>
      </li>
    );
    if (username) {
      const parsedUsername = username.split('#')[0];
      accNav = (
        <li className={classnames(classes.loggedInNav, {
          accountNavProtectClick: accountNavState,
        })}>
          <div className={classes.flex}>
            <div className={classes.avatar}>
              <img src={avatar} className={classes.avatarImg} alt={parsedUsername} />
            </div>
            <span className={classes.loggedInNavUsername}>
              {parsedUsername}
            </span>
            <span className={classes.loggedInNavCaretIcon}>
              <FontAwesomeIcon icon={['fas', 'caret-down']} transform={{
                rotate: accountNavState ? 180 : 0,
              }}/>
            </span>
          </div>
        </li>
      );
    }

    return (
      <ul className={classnames(classes.navbarRight, {
        [classes.loggedInNavBar]: Boolean(username),
      })} onClick={this.toggleAccountNav}>
        {accNav}
      </ul>
    );
  };

  accountNavMenu = () => {
    const { classes } = this.props;
    const { accountNavState } = this.state;
    return (
      <AccountNavMenu
        key={0}
        toggle={this.toggleAccountNav}
        visible={accountNavState}
        className={classnames(classes.accountNav, {
          [classes.accountNavClosed]: !accountNavState,
          accountNavProtectClick: accountNavState,
        })}
      />
    );
  };

  mobileNavToggle = () => {
    this.setState((state) => {
      return {
        mobileNavState: !state.mobileNavState,
      };
    });
  };

  render() {
    const { classes, username, className } = this.props;
    const { mobileNavState } = this.state;
    return [
      (username) ? this.accountNavMenu() : null,
      (
        <MobileNavOverlay key={1} visible={mobileNavState} toggle={this.mobileNavToggle} />
      ),
      (
        <div key={2} className={classnames(classes.root, className)}>
          <div className={classes.container}>
            <span onClick={() => this.props.history.push('/')} className={classes.logoLink}>
              <Logo className={classes.logo} overrideStyle fill="#fff" />
            </span>
            <div className={classes.navbar}>
              <ul>
                <Dropdown title="Games" Content={GamesDropdown} tailPosition={34} />
                <Dropdown
                  title={<FontAwesomeIcon
                      icon={['far', 'ellipsis-h']}
                      className={classes.verticalAlignMiddle}
                    />}
                  Content={ExtraDropdown}
                  tailPosition={18}
                />
              </ul>
            </div>
            <div className={classes.mobileRight} onClick={this.mobileNavToggle}>
              <FontAwesomeIcon icon={['fal', 'bars']} />
            </div>
            <ul className={classes.navbarRight}>
              <li>
                <a href="https://www.twitter.com/ZephyrGamingGG" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'twitter']} />
                </a>
              </li>
              <li>
                <a href="https://www.twitch.tv/ZephyrGamingGG" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'twitch']} />
                </a>
              </li>
            </ul>
            {this.authNav()}
          </div>
        </div>
      ),
    ];
  }
}

export default NavBar;
