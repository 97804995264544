import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import 'bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import 'typeface-roboto';
import AppProvider from './AppProvider';
import * as serviceWorker from './serviceWorker';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
  debug: process.env.REACT_APP_GA_DEBUG_MODE === 'true',
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
  testMode: (!process.env.REACT_APP_GA_TRACKING_ID || process.env.REACT_APP_GA_TRACKING_ID === '')
});

ReactDOM.render(<AppProvider/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
