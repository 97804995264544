import esportsBg from '../../images/backgrounds/esports_bg.jpg';
import esportsBgMobile from '../../images/backgrounds/esports_bg_mobile.jpg';

const styles = theme => ({
  root: {
    backgroundImage: `url(${esportsBg})`,
    minHeight: 'calc(100vh - 50px)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '100%',
    [theme.breakpoints.between('xs', 'md')]: {
      backgroundImage: `url(${esportsBgMobile})`,
    },
  },
  logo: {
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw',
      margin: '0 auto',
      textAlign: 'center',
    },
    filter: 'drop-shadow( -5px -5px 5px rgba(10,10,10,1) ) drop-shadow( 5px 5px 5px rgba(10,10,10,1) )',
    WebkitFilter: 'drop-shadow( -5px -5px 5px rgba(10,10,10,1) ) drop-shadow( 5px 5px 5px rgba(10,10,10,1) )',
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.verticalAlign(),
      maxWidth: '60%',
      margin: '0 auto',
    },
    color: '#fff',
  },
  itemLogo: {
    width: '100%',
    marginBottom: 20,
  },
  item: {
    ...theme.mixins.noSelect(),
    [theme.breakpoints.down('lg')]: {
      padding: '20px 20px 0 20px',
    },
    '& > $logo': {
      textAlign: 'center',
    },
    '& > h1, > p': {
      padding: 0,
      [theme.breakpoints.up('xl')]: {
        padding: '0 200px',
      },
      filter: 'drop-shadow( -5px -5px 5px rgba(10,10,10,1) ) drop-shadow( 5px 5px 5px rgba(10,10,10,1) )',
      WebkitFilter: 'drop-shadow( -5px -5px 5px rgba(10,10,10,1) ) drop-shadow( 5px 5px 5px rgba(10,10,10,1) )',
    },
    textAlign: 'center',
  },
  heroTitle: {
    fontFamily: 'Teko, sans-serif !important',
    fontWeight: 400,
  },
  discordButton: {
    backgroundColor: '#7289DA',
    color: '#fff',
    transition: 'all cubic-bezier(0.4, 0, 0.6, 1) 300ms',
    '&:hover': {
      transform: 'translateY(-2px)',
      backgroundColor: '#7289DA',
      boxShadow: theme.shadows[15],
    },
  },
});

export default styles;
