import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './TwitchStreamPanel.styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import client from '../../../utils/api';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { connect } from 'react-redux';

@withStyles(styles)
@connect(state => ({
  twitchUsername: state.apiState.profile.twitchUsername,
}))
class TwitchStreamPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.twitchUsername || '',
      loading: false,
      complete: false,
    };
  }

  // componentDidMount() {
  //   this.setState({
  //     value: this.props.twitchUsername,
  //   });
  // }

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  save = (event) => {
    const { value } = this.state;
    this.setState({
      loading: true,
      complete: false,
    });
    client(true).post('/account/profile/twitch', {
      twitchUsername: value,
    }).then(response => response.data)
      .then((data) => {
        this.setState({
          loading: false,
          complete: true,
        }, () => {
          setTimeout(() => {
            this.setState({
              loading: false,
              complete: false,
            });
          }, 3000);
        })
      });
  };

  render() {
    const { classes } = this.props;
    const { value, loading, complete } = this.state;
    return (
      <div className={classes.root}>
        <TextField
          id="outlined-name"
          label="Stream Username"
          value={value}
          onChange={this.handleChange}
          disabled={loading}
          InputProps={{
            startAdornment: <InputAdornment position="start">twitch.tv/</InputAdornment>,
          }}
        />
        <Button variant="contained" color="primary" onClick={this.save} className={classes.button}>
          Save
        </Button>
        <div className={classnames({
          [classes.hidden]: !loading,
          [classes.inline]: loading,
        })}>
          <CircularProgress className={classes.progress} />
        </div>
        <div className={classnames({
          [classes.hidden]: !complete,
          [classes.inline]: complete,
        })}>
          <CheckCircleIcon className={classes.complete} />
        </div>
      </div>
    );
  };
}

export default TwitchStreamPanel;
