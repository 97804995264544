import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './DisclaimerPage.styles';
import CookiePolicy from '../../legal/CookiePolicy';

@withStyles(styles)
class CookiePolicyPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CookiePolicy />
      </div>
    );
  }
}

export default CookiePolicyPage;
