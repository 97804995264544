import React from 'react';
import { summonSnackbar, dismissSnackbar } from '../../../components/Snackbar/Snackbar.actions';
import aionTranslate, { LANG_ASMO, LANG_ELYOS } from '../../../lib/aionTranslator';
import { actions } from './constants';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import styles from './AionTranslator.styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import TranslationTextArea from './TranslationTextArea';
import TranslateConfig from './TranslateConfig';
import ReactGA from 'react-ga';

@withStyles(styles)
@connect(state => ({
  open: state.snackbarState.open,
}), { summonSnackbar, dismissSnackbar })
class AionTranslator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elyos: '',
      asmo: '',
      type: LANG_ASMO,
      action: actions.UNDERSTAND,
    };
    this.copyButton = React.createRef();
    this.translateFrom = React.createRef();
    this.trackGaRecording = null;
  }

  _trackTranslations = () => {
    if (this.translateFrom.value.length > 0) {
      if (this.trackGaRecording !== null) {
        clearTimeout(this.trackGaRecording);
        this.trackGaRecording = null;
      }
      this.trackGaRecording = setTimeout(() => {
        const {elyos, asmo, type } = this.state;
        const label = {
          elyos, asmo,
          action: actions.SPEAK_TO === this.state.action ? 'SPEAK_TO' : 'UNDERSTAND',
          type: LANG_ASMO === type ? 'LANG_ASMO' : 'LANG_ELYOS',
        };
        if (actions.UNDERSTAND === this.state.action) {
          if (LANG_ASMO === type) {
            label.type = 'LANG_ELYOS';
          } else {
            label.type = 'LANG_ASMO';
          }
        }
        ReactGA.event({
          category: 'aion.tools.translator',
          action: (actions.SPEAK_TO === this.state.action) ? 'translate.to' : 'translate.from',
          label: JSON.stringify(label),
        });
        this.trackGaRecording = null;
      }, 5000);
    }
  };

  _handleEnterKey = (event) => {
    if ((event.keyCode || event.which) === 13 && !event.shiftKey) {
      event.preventDefault();
      this.copyButton.click();
      return false;
    }
  };

  _handleTextChange = (event) => {
    const { type } = this.state;
    let translated = aionTranslate(event.target.value, (type === LANG_ASMO) ? LANG_ELYOS : LANG_ASMO);
    let newElyos = type === LANG_ASMO ? event.target.value : translated;
    let newAsmo = type === LANG_ASMO ? translated : event.target.value;
    const newState = {
      elyos: newElyos,
      asmo: newAsmo,
    };
    this.setState(newState);
    this._trackTranslations();
  };

  changeLanguage = (type, action) => {
    this.setState((state) => {
      const isSwitching = state.type !== type;
      if (isSwitching) {
        this._trackTranslations(type);
        return {
          type, action,
          elyos: type === LANG_ASMO ? state.asmo : aionTranslate(state.elyos, LANG_ASMO),
          asmo: type === LANG_ASMO ? aionTranslate(state.asmo, LANG_ELYOS) : state.elyos,
        };
      }
    }, () => {
      this.translateFrom.focus();
    });
  };

  onCopy = (value) => {
    const { summonSnackbar, classes } = this.props;
    if (value !== '') {
      summonSnackbar('Copied to clipboard');
    } else {
      summonSnackbar([
        <WarningIcon key={1} className={classes.warningIcon} />,
        <span key={2}>Nothing to copy</span>
      ], { variant: 'error' });
    }
  };

  render() {
    const { classes } = this.props;
    const { asmo, elyos, type } = this.state;

    const copyOptions = {
      debug: process.env.NODE_ENV !== 'production',
      message: 'Press #{key} to copy',
    };

    return (
      <div>
        <Grid container
              spacing={24}
              className={classes.grid}
              direction="row"
              justify="center"
              alignItems="center">
          <Grid item xs={12}>
            <TranslateConfig changeLang={this.changeLanguage} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TranslationTextArea
              autoFocus={true}
              inputRef={el => this.translateFrom = el}
              value={type === LANG_ASMO ? elyos : asmo}
              race={type === LANG_ASMO ? 'elyos' : 'asmo'}
              handleChange={this._handleTextChange}
              handleKeyPress={this._handleEnterKey}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TranslationTextArea
              value={type === LANG_ASMO ? asmo : elyos}
              race={type === LANG_ASMO ? 'asmo' : 'elyos'}
              handleChange={this._handleTextChange}
              getRef={this.getRef}
              readOnly={true}
            />
          </Grid>
        </Grid>
        <Grid container
              spacing={24}
              className={classes.grid}
              direction="row"
              justify="center"
              alignItems="center"
              style={{textAlign: 'center'}}>
          <Grid item xs={12} md={12}>
            <CopyToClipboard options={copyOptions} text={type === LANG_ASMO ? asmo : elyos} onCopy={this.onCopy}>
              <Button variant="contained" color="primary" className={classes.button} buttonRef={el => this.copyButton = el}>
                Copy to Clipboard
              </Button>
            </CopyToClipboard>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AionTranslator;
