const styles = theme => ({
  dropdownIcon: {
    marginLeft: 10,
    verticalAlign: 'middle',
  },
  dropdown: {
    position: 'relative',
  },
  dropdownContent: {
    position: 'absolute',
    transform: 'translate3d(0px, 25px, 0px) translateY(30px)',
    top: 5,
    left: 0,
    willChange: 'transform',
    // display: 'none',
    pointerEvents: 'none',
    opacity: 0,
    borderRadius: 5,
    backgroundColor: '#101010',
    boxShadow: '0 2px 4px -1px rgba(0,0,0,.34),0 2px 2px -2px rgba(0,0,0,.26),0 1px 4px 0 rgba(0,0,0,.28)!important',
    border: '1px solid hsla(0,0%,100%,.05)!important',
    transition: 'all 200ms',
  },
  show: {
    // display: 'block',
    opacity: 100,
    pointerEvents: 'auto',
    transform: 'translateY(25px)',
  },
  dropdownLink: {
    cursor: 'pointer',
    color: 'rgba(255,255,255,0.8)',
    '&:hover': {
      color: '#fff',
    },
    ...theme.mixins.noSelect(),
  },
  light: {
    color: '#fff',
  },
  dropDownTail: {
    height: 8,
    width: 8,
    transform: 'rotate(45deg) translate(-50%,-50%)',
    transformOrigin: '0 0',
    WebkitTransform: 'rotate(45deg) translate(-50%,-50%)',
    borderTop: '1px solid hsla(0,0%,100%,.05)!important',
    borderLeft: '1px solid hsla(0,0%,100%,.05)!important',
    WebkitTransformOrigin: '0 0',
    position: 'absolute',
    backgroundColor: '#101010',
  },
  relative: {
    position: 'relative',
  },
});

export default styles;