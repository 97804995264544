const styles = theme => ({
  root: {},
  button: {
    margin: theme.spacing.unit,
  },
  hidden: {
    display: 'none',
  },
  complete: {
    color: '#008500',
  },
  inline: {
    display: 'inline',
  },
});

export default styles;
