import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import NavDrawerReducer from './components/NavDrawer/NavDrawer.reducer';
import apiReducer from './utils/api.reducer';
import modalReducer from './components/Modal/Modal.reducer';
import notificationReducer from './components/Notification/Notification.reducer';
import snackbarReducer from './components/Snackbar/Snackbar.reducer';

const AppReducer = combineReducers({
  router: routerReducer,
  mobileDrawerState: NavDrawerReducer,
  modalState: modalReducer,
  apiState: apiReducer,
  notificationState: notificationReducer,
  snackbarState: snackbarReducer,
});

export default AppReducer;
