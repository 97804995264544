const styles = theme => ({
  root: {
    ...theme.mixins.bodyPadding(),
    textAlign: 'center',
    width: '100%',
  },
  errorBanner: {
    composes: [ 'error-banner' ],
    margin: 'auto',
  },
});

export default styles;
