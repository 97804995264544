import {
  GET_USER, LOGOUT_USER, LOADING_USER,
} from './api.actions';

const INITAL_STATE = {
  username: null,
  admin: false,
  legionMember: false,
  roles: {},
  profileFields: {},
  retrieved: false,
  authenticated: false,
  loading: false,
  profile: {},
  avatar: null,
};

const reducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        username: action.data.username,
        admin: action.data.admin,
        legionMember: action.data.legionMember,
        roles: action.data.roles,
        retrieved: action.data.retrieved,
        authenticated: action.data.authenticated,
        profile: action.data.profile,
        avatar: action.data.avatar,
        loading: false,
      };
    case LOGOUT_USER:
      return {
        ...INITAL_STATE,
        retrieved: true,
        authenticated: false,
        loading: false,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
