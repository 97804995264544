const styles = theme => ({
  desktop: {
    transform: 'translateY(25px)',
    width: 80,
    '-webkit-filter': 'drop-shadow( -5px -5px 5px rgba(10,10,10,0.5) ) drop-shadow( 5px 5px 5px rgba(10,10,10,0.5) )',
    filter: 'drop-shadow( -5px -5px 5px rgba(10,10,10,0.5) ) drop-shadow( 5px 5px 5px rgba(10,10,10,0.5) )',
  },
  mobile: {
    width: 45,
  },
});

export default styles;
