const styles = theme => ({
  mobileOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    backgroundColor: '#101010',
    transition: 'all 300ms',
    pointerEvents: 'none',
    zIndex: 1020,
  },
  appear: {
    opacity: 1,
    pointerEvents: 'auto',
  },
  mobileOverlayNavLogo: {
    width: '100%',
    padding: '10px 0',
    textAlign: 'center',
    marginBottom: 40,
  },
  mobileOverlayExit: {
    color: '#fff',
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
    fontSize: 30,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 5,
    zIndex: 2000,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mobileOverlayNavItem: {
    width: '100%',
    padding: '10px 0',
    fontSize: 30,
    color: '#fff',
    textAlign: 'center',
  },
  transitionFrom: {
    transition: 'all 300ms',
    opacity: 0,
    transform: 'translateY(30px)',
  },
  transitionTo: {
    opacity: 1,
    transform: 'translateY(0px)',
  },
  account: {
    fontSize: 18,
  },
});

export default styles;
