const styles = theme => ({
  sentence: {
    textAlign: 'center',
  },
  text: {
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '1.4rem',
    },
  },
  textField: {
    verticalAlign: 'middle',
    transform: 'translate(0, -6px)',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 120,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 170,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      width: 180,
    },
  },
  select: {
    '&:-moz-focusring': {
      backgroundColor: '#101010',
      color: '#fff',
    },
  },
  inputField: {
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem',
      width: 120,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.2rem',
      width: 170,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      fontSize: '1.4rem',
      width: 180,
    },
    backgroundColor: '#101010',
    color: '#fff',
  },
  menu: {
    width: 250,
  },
  helpText: {
    marginTop: theme.spacing.unit * 3,
    textAlign: 'center',
  },
});

export default styles;
