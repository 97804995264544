import React from 'react';
import RoleTag from '../../RoleTag';

class RolesViewer extends React.Component {
  render() {
    const { roles } = this.props;
    return (
      <div>
        {roles.map((role, key) => <RoleTag key={key} name={role.name} color={role.color} />)}
      </div>
    );
  }
}

export default RolesViewer;
