import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import styles from './TimeTableModal.styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';

@withStyles(styles)
class TimeTableModal extends React.Component {
  constructor(props) {
    super(props);
    this.tz = moment.tz.guess();
  }

  render() {
    const { open, classes, title, event } = this.props;

    const schedule = event.getSchedule();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="time-table-title"
          aria-describedby="time-table-description"
          PaperProps={{
            classes: {
              root: classes.paperRoot,
            },
          }}
        >
          <DialogTitle id="time-table-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText component="div" id="time-table-description">
              <Paper className={classes.paper}>
                <p className={classes.text}>
                  <b>Current Timezone:</b> {this.tz}
                </p>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.cell}>Day</TableCell>
                      <TableCell className={classes.cell}>Opens</TableCell>
                      <TableCell className={classes.cell}>Closes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {schedule.map((ev, key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell className={classes.cell} component="th" scope="row">
                            {ev.open.tz(this.tz).format('dddd')}
                          </TableCell>
                          <TableCell className={classes.cell}>{ev.open.tz(this.tz).format('LT')}</TableCell>
                          <TableCell className={classes.cell}>{ev.close.tz(this.tz).format('LT')}{ev.close.tz(this.tz).format('dddd') !== ev.open.tz(this.tz).format('dddd') ? '*' : null}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <br />
                <Typography align="right">* = Next Day</Typography>
              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default TimeTableModal;
