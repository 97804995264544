import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './ProfilePage.styles';
import { connect } from 'react-redux';
import GameSelectionPanel from '../components/ProfileModules/GameSelectionPanel';
import LangSelectionPanel from '../components/ProfileModules/LangSelectionPanel';
import TwitchStreamPanel from '../components/ProfileModules/TwitchStreamPanel';
import RolesViewer from '../components/ProfileModules/RolesViewer';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';

@withStyles(styles)
@connect(state => ({
  username: state.apiState.username,
  roles: state.apiState.roles,
}))
class ProfilePage extends React.Component {
  render() {
    const { classes, roles } = this.props;

    return (
      <div className={classes.root}>
        <h2>Settings</h2>
        <div className={classes.profileNav}>
          <ul className={classes.profileNavUl}>
            <li className={classes.profileNavItem}>
              <NavLink
                activeClassName={classes.profileNavLinkActive}
                className={classes.profileNavLink}
                to="/profile"
              >My Profile</NavLink>
            </li>
          </ul>
        </div>
        <Divider />
        <Grid container>
          <Grid item sm={12}>
            <div className={classes.panel}>
              <h3>Roles</h3>
              <RolesViewer roles={roles.all.map(role => ({
                color: role.color,
                name: role.name,
              }))} />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.panel}>
              <h3>Games I play:</h3>
              <GameSelectionPanel />
              <small>Checking any of these will subscribe you to the game role. @ mentions to the game's role will ping you. Ask Vinlock to add more games!</small>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.panel}>
              <h3>Languages I Speak:</h3>
              <LangSelectionPanel />
              <small>Checking any of these will subscribe you to the language role. @ mentions to the language's role will ping you. Ask Vinlock to add more languages!</small>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.panel}>
              <h3>Twitch.tv Stream:</h3>
              <TwitchStreamPanel />
              <small>When you are live, you will see a red dot by your name in discord!</small>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ProfilePage;
