import './FontAwesomeProvider';
import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { CookiesProvider } from 'react-cookie';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'
import localForage from 'localforage';
import { JssProvider } from 'react-jss';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import compose from 'jss-compose';
import App from './App';
import AppReducer from './AppReducer';
import { SnackbarProvider } from 'notistack';
import Cookies from 'js-cookie';
import { createCookieMiddleware } from 'redux-cookie';

// Configure JSS
const jss = create({
  plugins: [
    ...jssPreset().plugins,
    compose(),
  ],
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'zephyr--',
});

const persistConfig = {
  key: '1L57kCwe2SaGdlLH5gyVO8GYLCTUoDb4UVlqSoPBz9uplvLVide6UQ8VIPU72UC6y51h5PNW1QGzQuoAwR7A5jwwjj0qi90u33Nu',
  storage: localForage,
  whitelist: [ ],
};

const persistedReducer = persistReducer(persistConfig, AppReducer);

const middleware = applyMiddleware(thunkMiddleware, createCookieMiddleware(Cookies));
export const store = createStore(
  persistedReducer,
  (process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true') ?
    composeWithDevTools(middleware)
    :
    middleware
);

const persistor = persistStore(store);

const AppProvider = (props) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <JssProvider jss={jss} generateClassName={generateClassName}>
          <SnackbarProvider maxSnack={3}>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </SnackbarProvider>
        </JssProvider>
      </PersistGate>
    </Provider>
  );
};

export default AppProvider;
