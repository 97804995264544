const styles = theme => ({
  root: {
    backgroundColor: 'rgba(16,16,16,0.95)',
    composes: [
      'container',
    ],
    color: '#c5c5c5',
    '& > h2': {
      padding: 20,
    }
  },
  panel: {
    padding: theme.spacing.unit * 2,
  },
  profileNav: {},
  profileNavUl: {
    composes: [ 'nav' ],
  },
  profileNavItem: {
    composes: [ 'nav-item' ],
  },
  profileNavLink: {
    composes: [ 'nav-link' ],
    color: '#fff',
    '&:hover': {
      color: '#6ab3df',
    },
  },
  profileNavLinkActive: {
    color: '#6ab3df',
  },
});

export default styles;
