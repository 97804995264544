const styles = theme => ({
  url: {
    width: '100%',
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
});

export default styles;
