const styles = theme => ({
  root: {
    color: '#fff',
    fontSize: 18,
    backgroundColor: '#101010',
    // borderBottom: '1px solid #106d90',
    zIndex: 1010,
    [theme.breakpoints.up('sm')]: {
      minWidth: 850,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    position: 'relative',
    backgroundColor: '#127ba2',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    zIndex: 1009,
  },
  navbar: {
    ...theme.mixins.noSelect(),
    padding: 10,
    paddingTop: 12,
    paddingLeft: 34,
    width: '100%',
    height: 49,
    listStyleType: 'none',
    '& > ul': {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      listStyleType: 'none',
      margin: 0,
      paddingLeft: 0,
      '& > li': {
        display: 'inline-block',
        paddingLeft: 10,
        paddingRight: 10,
        '& > a': {
          color: 'rgba(255,255,255,0.8)',
          '&:hover': {
            color: '#fff',
            textDecoration: 'none',
          },
        },
      },
    },
  },
  navbarRight: {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    verticalAlign: 'middle',
    paddingRight: 20,
    paddingLeft: 20,
    height: 50,
    minWidth: 280,
    textAlign: 'right',
    margin: 0,
    listStyleType: 'none',
    display: 'flex',
    '& li': {
      display: 'inline-block',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 12,
      '& a': {
        color: 'rgba(255,255,255,0.8)',
        '&:hover': {
          color: '#fff',
          textDecoration: 'none',
        },
      },
    },
  },
  loggedInNavBar: {
    '&:hover': {
      backgroundColor: '#1589b5',
      cursor: 'pointer',
    },
  },
  loggedInNav: {
    width: '100%',
    justifyContent: 'flex-end',
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loggedInNavUsername: {
    width: '50%',
    ...theme.mixins.noSelect(),
  },
  loggedInNavCaretIcon: {
    width: '50%',
    textAlign: 'right',
    verticalAlign: 'middle',
    justifyContent: 'flex-end',
  },
  loggedOutNav: {
    width: '100%',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  faButton: {
    position: 'relative',
    margin: '10px -20px 10px 0px',
    padding: 10,
    paddingRight: 20,
    fontWeight: 600,
    '& svg': {
      position: 'absolute',
      top: 12,
    },
  },
  faLinkText: {
    paddingLeft: 25,
  },
  accountNav: {
    color: '#fff',
    position: 'absolute',
    top: 50,
    right: 0,
    zIndex: 1008,
    transition: 'all 300ms',
    opacity: 1,
    WebkitFilter: 'drop-shadow( 5px 5px 5px rgba(10,10,10,.8) )',
    filter: 'drop-shadow( 5px 5px 5px rgba(10,10,10,.8) )',
  },
  accountNavClosed: {
    transform: 'translateY(-100%)',
    filter: 'none',
    '-webkit-filter': 'none',
    opacity: 0,
  },
  logo: {
    width: 27,
    position: 'absolute',
    top: 4,
    left: 23,
  },
  logoLink: {
    width: 50,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mobileRight: {
    textAlign: 'right',
    fontSize: 30,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 5,
    zIndex: 1011,
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  flex: {
    display: 'flex',
  },
  verticalAlignMiddle: {
    verticalAlign: 'middle',
  },
  avatar: {},
  avatarImg: {
    width: 30,
    composes: [
      'rounded-circle',
    ],
    transform: 'translate(-10px, -2px)'
  },
});

export default styles;
