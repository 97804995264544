import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './ExtraDropdown.styles';
import { withRouter, NavLink } from 'react-router-dom';

@withRouter
@withStyles(styles)
class ExtraDropdown extends React.Component {
  render() {
    const {
      classes,
      close,
      // history,
    } = this.props;
    return (
      <ul className={classes.root}>
        <li>
          <a href="https://medium.com/zephyr-community" target="_blank" rel="noopener noreferrer">Blog</a>
        </li>
        <li>
          <a href="mailto:business@zephyrgaming.gg" target="_blank" rel="noopener noreferrer">Business</a>
        </li>
        <li>
          <NavLink to="/legal/cookies" onClick={close}>
            Cookie Policy
          </NavLink>
        </li>
        <li>
          <NavLink to="/legal/disclaimer" onClick={close}>
            Disclaimer
          </NavLink>
        </li>
      </ul>
    );
  }
}

export default ExtraDropdown;
