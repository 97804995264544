import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import zephyrWide from '../../images/logos/zephyr_wide.png';
import styles from './HeroAbout.styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

const OAUTH_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/discord/oauth`;

@withStyles(styles)
@connect(state => ({
  username: state.apiState.username,
}))
class HeroAbout extends React.Component {
  loginWithDiscord = () => {
    ReactGA.event({
      category: 'homepage.discord',
      action: 'login',
    });
    window.location = `${OAUTH_ENDPOINT}?redirect_url=${window.location.href}`;
  };

  render() {
    const { classes, username } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          className={classes.container}
          alignContent="center"
          alignItems="center"
          container={true}
          wrap="wrap"
        >
          <Grid className={classnames(classes.itemLogo, classes.item)} item={true} sm={12}>
            <img alt="Zephyr Gaming Community" className={classes.logo} src={zephyrWide} />
          </Grid>
          <Grid className={classes.item} item={true} sm={12}>
            <h1 className={classes.heroTitle}>
              Our mission is to unite gamers and strive to consistently improve together.
            </h1>
            <p>
              We are Gaming Community that seeks to foster home for gamers from all different genres and titles. We
              support organizations of any size -- from groups of friends to top tier guilds -- utilizing our collective
              resources and expanding their reaches to attain goals faster, together.
            </p>
            {!username ? (
              <Button variant="contained" onClick={this.loginWithDiscord} classes={{
                contained: classes.discordButton,
              }}>Login With Discord</Button>
            ) : null}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default HeroAbout;
