const styles = theme => ({
  root: {
    padding: 5,
    width: '90vw',
    '& > div': {
      width: 150,
      margin: 5,
      textAlign: 'center',
      display: 'inline-block',
      backgroundColor: '#101010',
      borderRadius: 10,
      transition: 'all 300ms',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.05)',
      },
      '& > img': {
        maxWidth: 150,
        verticalAlign: 'middle',
        opacity: 0.9,
        transition: 'all 300ms',
        WebkitFilter: 'grayscale(100%)',
        filter: 'grayscale(100%)',
        '&:hover': {
          cursor: 'pointer',
          opacity: 1,
          WebkitFilter: 'grayscale(0%)',
          filter: 'grayscale(0%)',
        },
      },
    },
  },
  clear: {
    clear: 'both',
  },
});

export default styles;