const styles = theme => ({
  root: {
    backgroundColor: 'rgba(16,16,16,0.95)',
    composes: [
      'container',
    ],
    color: '#fff',
  },
  title: {
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 300,
    textAlign: 'center',
  },
});

export default styles;
