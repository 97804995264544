import io from 'socket.io-client';
import { store } from '../AppProvider';
import { logoutUser } from '../utils/api.actions';
import { openModal } from '../components/Modal/Modal.actions';

class Socket {
  constructor() {
    this.connection = null;
    this.queue = [];
  }

  on = (...args) => {
    if (!this.connection) {
      this.queue.push(() => {
        this.connection.on(...args);
      });
    } else {
      this.connection.on(...args);
    }
  };

  emit = (...args) => {
    if (!this.connection) {
      this.queue.push(() => {
        this.connection.emit(...args);
      });
    } else {
      this.connection.emit(...args);
    }
  };

  connect = (token) => {
    this.connection = io(process.env.REACT_APP_SOCKET_URL, {
      query: { token },
    });

    this.connection.on('reconnect_attempt', () => {
      this.connection.io.opts.query = { token };
    });

    // Process Queue
    this.queue.forEach((q) => {
      q();
    });
  };
}

const socket = new Socket();

socket.on('logoutUser', () => {
  store.dispatch(logoutUser());
});

socket.on('openModal', (data) => {
  store.dispatch(openModal(data.title, data.content, data.actions));
});

export default socket;
