import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './LangSelectionPanel.styles';
import Checkbox from '@material-ui/core/Checkbox';
import client from '../../../utils/api';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { getUser } from '../../../utils/api.actions';

const languages = {
  english: 'English',
  spanish: 'Spanish',
  korean: 'Korean',
  japanese: 'Japanese',
};

@withStyles(styles)
@connect(state => ({
  username: state.apiState.username,
  roles: state.apiState.roles,
  admin: state.apiState.admin,
  legionMember: state.apiState.legionMember,
  authenticated: state.apiState.authenticated,
  loading: state.apiState.loading,
}), { getUser })
class LangSelectionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      disabled: {},
    };
  }

  componentDidMount() {
    const { langs } = this.props.roles;
    this.setState({
      options: langs,
    });
  }

  handleChange = (option) => (event) => {
    const { checked } = event.target;
    this.setState((state) => {
      return {
        options: Object.assign(state.options, {
          [option]: checked,
        }),
        disabled: Object.assign(state.disabled, {
          [option]: true,
        }),
      };
    });
    client(true).post(`/account/profile/languages/${option}`, {
      value: event.target.checked,
    }).then(response => response.data)
      .then((data) => {
        this.setState((state) => {
          return {
            options: data,
            disabled: Object.assign(state.disabled, {
              [option]: undefined,
            }),
          };
        });
      });
  };

  render() {
    const { classes, loading } = this.props;
    const { options, disabled } = this.state;
    if (loading || options === {}) return (
      <div>
        <CircularProgress className={classes.progress} />
      </div>
    );
    return (
      <div className={classes.root}>
        <FormGroup row>
          {Object.keys(options).map((option, key) => {
            return (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={this.state.options[option]}
                    onChange={this.handleChange(option)}
                    value={option}
                    color="primary"
                    disabled={disabled[option] === true}
                  />
                }
                label={languages[option]}
              />
            );
          })}
        </FormGroup>
      </div>
    )
  }
}

export default LangSelectionPanel;
