import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './Home.styles';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import HeroAbout from './HeroAbout';

@withStyles(styles)
@withCookies
@connect(state => ({
  authenticated: state.apiState.authenticated,
  username: state.apiState.username,
}))
class Home extends React.Component {
  render() {
    return <HeroAbout />;
  }
}

export default Home;
