import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { AuthRoute } from 'react-router-auth';
import ReactGA from 'react-ga';
import createHistory from 'history/createBrowserHistory';
import { withStyles } from '@material-ui/core/styles';
import styles from './AppRouter.styles';
import NavBar from './components/NavBar';
import Home from './pages/Home/Home';
import ProfilePage from './pages/ProfilePage';
import DisclaimerPage from './pages/legal/DisclaimerPage';
import CookiePolicyPage from './pages/legal/CookiePolicyPage';
import Error404Page from './pages/Error404Page';
import { connect } from 'react-redux';
import OnPageChangeContainer from './utils/OnPageChangeContainer';
import AionRoot from './pages/Aion/AionRoot';
import Snackbar from './components/Snackbar/Snackbar';

const history = createHistory();
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

@withStyles(styles)
@connect(state => ({
  admin: state.apiState.admin,
  authenticated: state.apiState.authenticated,
}))
class AppRouter extends React.Component {
  render() {
    const { classes, authenticated } = this.props;

    return (
      <Router history={history}>
        <div className={classes.routerRoot}>
          <NavBar className={classes.navBar} />
          <div className={classes.contentBody} id="scrollableDiv">
            <div className={classes.body}>
              <OnPageChangeContainer>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Redirect from="/schedule" to="/aion/tools/schedule" />
                  <AuthRoute exact path="/profile" redirectTo="/404" component={ProfilePage} authenticated={authenticated} />
                  <Route path="/legal/disclaimer" component={DisclaimerPage} />
                  <Route path="/legal/cookies" component={CookiePolicyPage} />
                  <Route path="/aion" component={AionRoot} />
                  <Route exact path="/404" component={Error404Page} />
                  <Redirect from="*" to="/404" />
                </Switch>
              </OnPageChangeContainer>
            </div>
           <Snackbar />
          </div>
        </div>
      </Router>
    );
  };
}

export default AppRouter;

export {
  history,
};
