import { SUMMON_SNACKBAR, DISMISS_SNACKBAR } from './Snackbar.actions';

const INITIAL_STATE = {
  open: false,
  message: null,
  vertical: 'bottom',
  horizontal: 'center',
  icon: null,
  variant: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUMMON_SNACKBAR:
      return {
        ...state,
        open: true,
        message: action.message,
        vertical: action.vertical,
        horizontal: action.horizontal,
        icon: action.icon,
        variant: action.variant,
      };
    case DISMISS_SNACKBAR:
      return {
        ...INITIAL_STATE,
        variant: state.variant,
        vertical: state.vertical,
        horizontal: state.horizontal,
      };
    default:
      return state;
  }
};

export default reducer;
