import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './Dropdown.styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const dropdowns = [];

@withStyles(styles)
class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    // Name dropdown
    do {
      this.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    } while(dropdowns.includes(this.id));
    dropdowns.push(this.id);

    // Close on outside click
    const { toggleDropdown, id } = this;
    this.runClickFunction = (event) => {
      const { open } = this.state;
      if (event.target.closest(`#${id}`) === null && open) {
        toggleDropdown();
        document.removeEventListener('click', this.runClickFunction);
      }
    };
  }

  componentWillUnmount() {
    const index = dropdowns.indexOf(this.id);
    if (index > -1) {
      dropdowns.splice(index, 1);
    }
  }

  toggleDropdown = () => {
    this.setState((state) => {
      if (!state.open) {
        document.addEventListener('click', this.runClickFunction);
      }
      return {
        open: !state.open,
      };
    });
  };

  closeDropdown = () => {
    this.setState({ open: false });
  };

  render() {
    const { title, Content, classes, tailPosition } = this.props;
    const { open } = this.state;
    return (
      <li className={classes.dropdown} id={this.id}>
        <div className={classnames(classes.dropdownLink, {
          [classes.light]: open,
        })} onClick={this.toggleDropdown}>
          <span>{title}</span>
        </div>
        <div
          className={classnames(classes.dropdownContent, {
            [classes.show]: open,
          })}
        >
          <div className={classes.relative}>
            <div className={classes.dropDownTail} style={{ left: tailPosition }}></div>
            {<Content close={this.closeDropdown} />}
          </div>
        </div>
      </li>
    );
  }
}

Dropdown.defaultProps = {
  tailPosition: 30,
};

Dropdown.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  tailPosition: PropTypes.number,
  Content: PropTypes.func.isRequired,
};

export default Dropdown;