const styles = theme => ({
  root: {
    borderRadius: 12,
    height: 24,
    fontFamily: 'Arial, sans-serif',
    fontSize: 14,
    paddingLeft: 5,
    paddingRight: 5,
    margin: 5,
    display: 'inline-block',
    '& > p': {
      ...theme.mixins.verticalAlign(),
    },
    '&:hover': {
      cursor: 'default',
    },
  },
});

export default styles;
