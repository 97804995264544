const styles = theme => ({
  labelRoot: {
    color: '#fff',
    fontSize: 18,
    zIndex: 1016,
    marginLeft: 10,
  },
  labelElyos: {
    '&$labelElyosFocused': {
      color: '#69936f',
      margin: 0,
      [theme.breakpoints.between('xs', 'sm')]: {
        marginTop: -40,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        // height: 150,
      },
      [theme.breakpoints.between('md', 'xl')]: {
        // height: 200,
      },
    },
  },
  labelElyosFocused: {},
  labelAsmo: {
    '&$labelAsmoFocused': {
      color: '#668093',
      margin: 0,
    },
  },
  labelFilled: {
    '&:not($labelElyosFocused), &:not($labelAsmoFocused)': {
      margin: 0,
    },
  },
  labelAsmoFocused: {},
  note: {
    [theme.breakpoints.between('xs', 'sm')]: {
      textIndent: -9999,
    },
    textAlign: 'left',
  },
  labelShrink: {
    margin: '0px !important',
  },
  formLabelFormControl: {
    margin: 10,
  },
  labelFormControl: {},
  textCounter: {
    textAlign: 'right',
  },
  textCounterWarning: {
    color: '#ffe062',
  },
  textCounterLightDanger: {
    color: '#d57f7f',
  },
  textCounterDanger: {
    color: '#ac0000',
  },
  readOnly: {
    '&:focus': {
      borderColor: 'red',
    }
  },
  hide: {
    display: 'none',
  },
  input: {
    borderRadius: 4,
    border: '1px solid #054c10',
    backgroundColor: '#054c10',
    fontSize: 16,
    padding: '10px 12px',
    width: '100%',
    transition: theme.transitions.create([
      'border-color',
      'box-shadow'
    ]),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  inputElyos: {
    border: '1px solid #054c10',
    backgroundColor: '#054c10',
  },
  inputAsmo: {
    border: '1px solid #002C4B',
    backgroundColor: '#002C4B',
  },
  bootstrapRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
    width: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
      height: 'inherit',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 150,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      height: 200,
    },
  },
  bootstrapFocus: {
    '&:focus': {
      borderColor: '#ac0000',
      boxShadow: '0 0 0 0.2rem rgba(172,0,0,.25)',
    },
  },
  formControl: {
    width: '100%',
  },
  lightTooltip: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    textAlign: 'center',
  },
});

export default styles;
