import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppRouter from './AppRouter';
import AppTheme from './AppTheme';
import { getUser, logoutUser } from './utils/api.actions';
import { withCookies } from 'react-cookie';
import Modal from './components/Modal';
import { Helmet } from "react-helmet";
import Socket from './lib/socket';

@withCookies
@connect((state) => ({
  retrieved: state.apiState.retrieved,
}), { getUser, logoutUser })
class App extends React.Component {
  componentDidMount() {
    const { cookies, getUser, logoutUser } = this.props;
    const token = cookies.get('zjwt_token');
    if (token) {
      getUser();

      // Connect Socket
      Socket.connect(token);
    } else {
      logoutUser();
    }
  }

  router = () => {
    const { retrieved } = this.props;
    if (retrieved) {
      return <AppRouter/>;
    }
    return null;
  };

  render() {
    return (
      <AppTheme>
        <Helmet>
          <title>Home - Zephyr Gaming Community</title>
        </Helmet>
        <CssBaseline/>
        {this.router()}
        <Modal />
      </AppTheme>
    );
  }
}

export default App;
