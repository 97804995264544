import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './RoleTag.styles';
import PropTypes from 'prop-types';
import Color from 'color';

const hexToRgb = (hex) => {
  // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    b: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    r: parseInt(result[3], 16)
  } : null;
};

@withStyles(styles)
class RoleTag extends React.Component {
  getRgba = (opacity = 1) => {
    const { color } = this.props;
    if (color === '#000' || color === '#000000') {
      return `rgba(255,255,255,${opacity})`;
    }
    const converted = hexToRgb(color);
    if (opacity < 1) {
      return `rgba(${converted.r}, ${converted.g}, ${converted.b}, ${opacity})`;
    }
    return `rgb(${converted.r}, ${converted.g}, ${converted.b})`;
  };

  render() {
    const { classes, name } = this.props;
    const color = this.getRgba();
    const textColor = Color(color).lighten(0.5);
    const transparentColor = this.getRgba(0.2);
    const style = {
      color: textColor.rgb().string(),
      backgroundColor: transparentColor,
      border: `1px solid ${color}`,
    };
    return (
      <div style={style} className={classes.root}>
        <p>{name}</p>
      </div>
    );
  }
}

RoleTag.defaultProps = {
  color: '#fff',
  name: 'No Role',
};

RoleTag.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
};

export default RoleTag;
