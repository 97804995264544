const styles = theme => ({
  root: {
    listStyleType: 'none',
    padding: '5px 10px 5px 10px',
    width: 200,
    '& > li > span, a': {
      boxSizing: 'border-box',
      fontSize: 14,
      margin: '5px 0px 5px 0px',
      width: '100%',
      display: 'block',
      backgroundColor: '#101010',
      padding: '5px 10px 5px 10px',
      verticalAlign: 'baseline',
      borderRadius: 3,
      color: '#fff',
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'none',
        backgroundColor: '#127ba2',
      },
    },
  },
});

export default styles;