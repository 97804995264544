import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './GamesDropdown.styles';
import aion from '../../images/logos/aion.png';
import { withRouter } from 'react-router-dom';

@withRouter
@withStyles(styles)
class GamesDropdown extends React.Component {
  goTo = (path) => () => {
    const { history, close } = this.props;
    history.push(path);
    close();
  };

  render() {
    const { classes } = this.props;

    const games = [
      <div className="gamesDropdownGame" key={0} onClick={this.goTo('/aion')}>
        <img alt="Aion Online" src={aion} />
      </div>,
    ];

    return (
      <ul className={classes.root} style={{maxWidth: 170 * games.length}}>
        {games}
      </ul>
    );
  }
}

export default GamesDropdown;
