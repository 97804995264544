import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './Logo.styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';

@withStyles(styles)
class Logo extends React.Component {

  render() {
    const { classes, mobile, className, overrideStyle, fill } = this.props;

    let rootClass = classes.desktop;
    if (mobile) {
      rootClass = classes.mobile;
    }

    rootClass = classnames(className, rootClass);
    if (overrideStyle) {
      rootClass = className;
    }

    return (
      <svg className={rootClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3097.31 4674.66">
        <path
          d="M3884.58,2367.26a4.52,4.52,0,0,0,.1-2.34C3884.65,2365.71,3884.6,2366.47,3884.58,2367.26Z"
          transform="translate(-892.4 -127.56)"
          fill={fill || "#00aeef"}
        />
        <path
          d="M1221.13,2831.66s144.51,724.41,613.09,1168.17c557.48,527.94,724.38,640.34,724.38,640.34,329.62-383,1277.33-832.31,1326-2272.91-15.2,56.63-851.2,628.73-1682.5,896.86-144.2,46.51,87.25,345.12,244.3,490.53,9,8,18.07,15.81,27.25,23.59-134.26-123.09-223.84-359.71-70.59-405.12,82.58-24.48,1207.2-480.69,1195.53-521.13-76.64,812.34-710.17,1057.58-1040,1440.76,0,0,0,20.44-557.48-507.5-468.59-443.76-453-739.12-453-739.12,2511.42-677.8,2388.32-1713.95,2388.32-1713.95-33.57,61.91-54.47,124.38-100.69,180.42-34.35,41.64-91.72,107.3-144.26,122,51.63-111.67,55.22-255.57,46.41-342.25-9.1-89.55-22.76-182.2-48.28-269.2C3689.61,1023.15,3980.43,2222.34,1221.13,2831.66Z"
          transform="translate(-892.4 -127.56)"
          fill={fill || "#2aace3"}
        />
        <path
          d="M1548.11,3046.13s-15.58,295.36,453,739.12c557.48,527.94,557.48,507.5,557.48,507.5,329.8-383.18,963.33-628.42,1040-1440.76,11.67,40.44-1113,496.65-1195.53,521.13-153.25,45.41-63.67,282,70.59,405.12,8.81,7.48,17.6,15,26.47,22.35-14.58-71.05-53.85-141.34-42-215.71,11.38-71.45,65.18-110.72,129-132,55.93-18.63,111.16-42,166.06-63.58,234.19-92,462.15-197.46,680.41-323.18,2.76-1.59,5.73-2.82,8.69-4.27.54,1.69,1.32,2.82,1.08,3.65-1.22,4.22-2.65,8.39-4.12,12.54-86.25,242.95-222.85,453.65-408,632.64C2879.73,3857.2,2726.8,4002.28,2574.47,4148c-3.52,3.36-7.29,6.46-11.75,10.39-6.71-5.84-12.91-11.17-19-16.58q-216.15-190.42-432.24-380.91-283-250.15-429.95-598.3c-3.19-7.57-6.23-15.21-9.22-22.52,1.6-1.41,2.3-2.49,3.24-2.79,10.51-3.3,21-6.59,31.59-9.67,270.44-78.84,537.59-167.39,799.31-271.85,222.91-89,440.78-188.68,649.61-307.43,161.46-91.8,316-193.73,457.25-314.86,134.11-115,277.56-255.79,342.43-423.42,59.59-154,31.82-311.68-14-464.77a75.78,75.78,0,0,0-5.27-13.08S4059.53,2368.33,1548.11,3046.13Z"
          transform="translate(-892.4 -127.56)"
          fill={fill || "#0988b5"}
        />
        <path
          d="M3975.45,2079.38c-12.3,17.65-24.18,35.61-37,52.88-77.12,103.88-168.84,193.66-268.13,276-127.33,105.6-264.77,196.51-407.91,279-211.51,121.91-432.58,223.83-658.88,314.78-202.05,81.21-407.64,152.4-615.3,217.74-5,1.56-9.77,3.56-15.3,5.59,2.49,4.93,4.1,8.41,6,11.74,54.62,97.87,119.43,188.57,199.32,267.15,77.47,76.2,158.37,148.92,237.81,223.12,9.9,9.25,20.14,18.25,30.36,27.26-157.05-145.41-388.5-444-244.3-490.53,831.3-268.13,1667.3-840.23,1682.5-896.86,0-.79.07-1.55.1-2.34a4.52,4.52,0,0,1-.1,2.34c-48.65,1440.6-996.36,1889.94-1326,2272.91,0,0-166.9-112.4-724.38-640.34-468.58-443.76-613.09-1168.17-613.09-1168.17,2759.3-609.32,2468.48-1808.51,2468.48-1808.51-47.08,98.89-149.26,259.38-280.08,311.43,99.63-166,114.95-275.89,73.82-703-3.23,2.94-4.8,6.19-6.34,9.45-24,50.87-46.77,102.34-72.15,152.49-79,156.22-173.65,302.19-289.6,433.8-97.25,110.38-89.22,110.55-200.15,193.82-1.89,1.42-60.59,43.63-63.51,46.1,174.83,111,221.34,170,396,281.06-16.79,12.71-45.93,132.61-197.44,245.34-172.49,128.34-132.89,99.39-319.37,203-193.38,107.47-401.27,185-608.47,262.3-320.4,119.5-647.47,217.5-978.1,304.08-5.66,1.48-11.23,3.27-16.77,4.89,8.94,70.22,21.16,138,36.55,205.23q102.45,447.3,365.36,823.5c103,147.7,220.08,283.38,354.54,403.13,213,189.75,427.89,377.5,642,566.08,13.22,11.64,26.51,23.2,41.52,36.33,7.19-6.79,13.18-12.39,19.11-18.06q235.52-225.57,471-451.19c81.22-77.92,164-154.36,242.72-234.74q337.82-344.88,514-795,139.32-355.89,155.91-737.93c3.85-87.07,5-174.27,7.62-261.4,2.27-73.9,5-147.78,7.46-221.67.14-4.11,0-8.22,0-12.33C3980.12,2072.56,3977.82,2076,3975.45,2079.38Z"
          transform="translate(-892.4 -127.56)"
          fill={fill || "#a5deef"}
        />
        <path
          d="M1169.34,2330c27.28-78,36.1-103.31,63.13-181.44,3.16-9.13,26.47-68.95,34.36-75,348.33-266.92,1072.51-641.81,1072.51-641.81S2606,1404,2705,1384.57c14.71-2.88,26.75-8.82,38.32-17.71,42.65-32.74,80.74-70.18,115.89-110.73,56.15-64.79,103.48-136,151.84-206.56,103.6-151.17,171.75-317.49,215.36-494.7,28.54-116,46.15-233.6,54.9-352.61,1.81-24.61,2.45-49.3,3.63-73.95l-3.26-.75c-11.31,22-22.15,44.18-34,65.84-61,111.37-136.43,212.48-220.19,307.53-166,188.35-361.64,357-583.53,476-215.19,115.42-448.14,192.46-666.62,301-199.52,99.08-384.9,223.52-567.26,350.76-69.19,48.27-137,93.07-202.63,146.43-27.47,22.35-58.23,40.33-85,63.76-9.69,8.5-32.52,25-29.91,40,3.13,17.93,52.41,38.87,66.67,47,45.78,26.11,100.86,58.82,117.69,111.54,20,62.72,21.47,139.2,26.54,204.53q4.29,55.53,5.5,111.23c.58,27.19-8.11,74.34,2.47,97.39,2.79-1.72,3.43-3.86,4.81-6.78,14-29.36,28-58.65,41.92-88C1162.2,2347.22,1166.27,2338.76,1169.34,2330Z"
          transform="translate(-892.4 -127.56)"
          fill={fill || "#0988b5"}
        />
        <path
          d="M2534.15,1839.31c24.73-77.18,39.64-158,84.51-228.85-5.75,0-10.63-.17-15.5,0-106.95,4.22-213.89,8.6-320.84,12.58-10.93.41-26.84,27.38-36,33.39-257.46,169.59-733.25,427.91-733.25,427.91s-70.93,77.12-83.27,92.79-16.33,38.18-23.32,57.91c-23.57,66.57-56.44,203.7-56.44,203.7l-11.44,84.53s17-26.76,19.59-31.5c40.58-75.56,144.11-207,144.11-207l73-94.44s290.22-147.89,405.06-187.73a45.65,45.65,0,0,1,17.73-2.55c94.64,6.77,189.25,13.89,283.87,20.83q156.8,11.49,313.6,22.79c2,.14,3.92,0,8.27,0-4.6-6.15-7.86-10.39-11-14.72-20.33-28.15-41.14-56-60.69-84.67-7-10.31-11.86-22.42-15.95-34.31-1.94-5.61-1-13,.78-18.94C2522.18,1873.66,2528.6,1856.62,2534.15,1839.31Z"
          transform="translate(-892.4 -127.56)"
          fill={fill || "#0988b5"}
        />
      </svg>
    );
  }
}

Logo.defaultProps = {
  mobile: false,
  overrideStyle: false,
};

Logo.propTypes = {
  mobile: PropTypes.bool,
  overrideStyle: PropTypes.bool,
};

export default Logo;
