import _ from 'lodash';

export const OPEN_NOTIFICATION = 'OPEN_NOTIFICATION';

export const summonSnackbar = (message, options) => {
  options = _.defaults(options, {
    vertical: 'bottom',
    horizontal: 'center',
    variant: null,
    icon: null,
    action: null,
  });
  return (dispatch, getState) => {
    const summon = () => dispatch({
      type: OPEN_NOTIFICATION,
      message,
      ...options,
    });
    const {open} = getState().notificationState;
    if (open) {
      dispatch(dismissSnackbar());
      setTimeout(summon, 250);
    } else {
      summon();
    }
  };
};

export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';

export const dismissSnackbar = () => ({
  type: CLOSE_NOTIFICATION,
});
