import React from 'react';
import GameEvent from './GameEvent';
import Grid from '@material-ui/core/Grid';

class Scheduler extends React.Component {
  getEvents = () => {
    const { schedule } = this.props;
    return schedule.map((event, key) => (
      <Grid item key={key} xs={12} md={6}>
        <GameEvent event={event} />
      </Grid>
    ));
  };
  render() {
    return (
      <Grid container>
        {this.getEvents()}
      </Grid>
    )
  }
}

export default Scheduler;
